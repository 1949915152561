import cn from "classnames";
import { useGetAllGroupsQuery } from "features/api/user-api";
import type { Option } from "models/common";
import type { UserGroup, UserGroupId } from "models/user.model";
import { type IntlShape, useIntl } from "react-intl";
import { ETranslations } from "types/translates";
import { SelectBasic } from "ui-kit";

import { getRoleGroupName } from "../../RoleList/utils";
import styles from "./UserForm.module.scss";

const getRoleOptions = (
  roles: UserGroup[] | undefined,
  defaultRoleId: UserGroupId | undefined,
  t: IntlShape["formatMessage"],
) =>
  roles
    ? roles.reduce<{
        options: Option<UserGroupId>[];
        defaultRole: Option<UserGroupId> | undefined;
      }>(
        (result, role) => {
          defaultRoleId &&
            defaultRoleId === role.id &&
            (result.defaultRole = {
              value: role.id,
              label:
                role.group_translation_name ||
                getRoleGroupName(role.group_name, t),
            });
          result.options.push({
            value: role.id,
            label:
              role.group_translation_name ||
              getRoleGroupName(role.group_name, t),
          });
          return result;
        },
        { options: new Array<Option<UserGroupId>>(), defaultRole: undefined },
      )
    : { options: [], defaultRole: undefined };

export const RoleSelect = ({
  isDefaultEditRole,
  defaultRoleId,
  error,
}: {
  isDefaultEditRole?: boolean;
  defaultRoleId?: UserGroupId;
  error?: string | string[];
}) => {
  const { formatMessage } = useIntl();
  const { data } = useGetAllGroupsQuery();
  const { options, defaultRole } = getRoleOptions(data, defaultRoleId, formatMessage);
  return (
    <>
      <input
        className={styles.roleSelectCheckbox}
        hidden
        type="checkbox"
        id="role-select"
        defaultChecked={isDefaultEditRole}
      />
      <label
        className={cn(styles.textButton, styles.spanAllColumns)}
        htmlFor="role-select"
      >
        {formatMessage(
          { id: ETranslations.BASE_ENTITY_CHANGING },
          {
            entity: formatMessage({ id: ETranslations.ROLE }).toLowerCase(),
          },
        )}
      </label>
      <SelectBasic
        defaultValue={defaultRole}
        name="role"
        options={options}
        required
        label={formatMessage({ id: ETranslations.ROLE })}
        className={cn(styles.roleSelect, styles.spanAllColumns)}
        error={error}
      />
      <hr className={cn(styles.divider, styles.spanAllColumns)} />
    </>
  );
};
