import dayjs from "dayjs";
import type { ReactNode } from "react";
import { useIntl } from "react-intl";
import { ETranslations } from "types/translates";
import { ICONS } from "ui-kit";
import { RadioGroup } from "ui-kit/RadioButton/RadioButton";

import styles from "./MultiShiftRadioButtons.module.scss";
import { useMultiShiftNavigation } from "./useMultiShiftNavigation";
import { type ButtonParam, serializeShiftData } from "./utils";

const MultiShiftRadioButtons = ({ children }: { children: ReactNode }) => {
  const { formatMessage } = useIntl();
  const { buttonsRef, links, prev, next } = useMultiShiftNavigation();
  return (
    <RadioGroup.Root
      variant="bordered"
      groupName="shift"
      className={styles.radioGroupContainer}
      label={formatMessage({ id: ETranslations.AVAILABLE_TIME })}
      required
    >
      {links ? (
        <a className={styles.arrow} href={prev}>
          <ICONS.LeftArrow />
        </a>
      ) : undefined}
      <RadioGroup.Content className={styles.multiShiftButtons} ref={buttonsRef}>
        {children}
      </RadioGroup.Content>
      {links ? (
        <a className={styles.arrow} href={next}>
          <ICONS.RightArrow />
        </a>
      ) : undefined}
    </RadioGroup.Root>
  );
};

const getMultiShiftButtonsParam = ({
  shiftId,
  startDate,
  endDate,
  places,
  shiftName,
  defaultShiftId,
}: {
  shiftId: number;
  startDate: string;
  endDate: string;
  places: number[];
  shiftName: string;
  defaultShiftId: number;
}): ButtonParam => {
  const startShift = dayjs.tz(startDate);
  const endShift = dayjs.tz(endDate);
  const startHour = startShift.hour();
  // Если смена заканчивается не в день startShift, то указываем, что смена до 24 часов этого дня
  const endHour = (startShift.isSame(endShift, "day") && endShift.hour()) || 24;
  return {
    text: shiftName,
    value: serializeShiftData({ shiftId, startHour, endHour, places }),
    defaultChecked: shiftId === defaultShiftId,
  };
};

MultiShiftRadioButtons.displayName = "MultiShiftRadioButtons";
export { MultiShiftRadioButtons, getMultiShiftButtonsParam };
