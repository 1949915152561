import type { RestaurantId } from "models/booking.model";
import type { Restaurant } from "models/restaurant.model";
import type { GrantedUser, UserGroup, UserUUID } from "models/user.model";
import type { IntlShape } from "react-intl";
import { ETranslations } from "types/translates";
import type { CollapsesOption } from "ui-kit";
import { invariant } from "utils";

const getRestaurantNamesString = (
  restaurantIds: RestaurantId[],
  restaurantNamesById: Record<RestaurantId, string>,
) =>
  restaurantIds
    .map((id) => restaurantNamesById[id])
    .sort((a, b) => a.localeCompare(b))
    .join(", ");

const getPhoneString = (
  extensionPhone: string | null | undefined,
  phone: string | null | undefined,
) =>
  phone && extensionPhone
    ? `${extensionPhone}, ${phone}`
    : `${extensionPhone || ""}${phone || ""}`;

export const getRoleGroupName = (
  systemName: string,
  t: IntlShape["formatMessage"],
) => {
  const upperCasedName = systemName.toUpperCase();
  return upperCasedName in ETranslations
    ? t({ id: upperCasedName })
    : systemName;
};

export const getRoleListOptions = ({
  restaurants,
  groups,
  users,
  filter,
  t,
}: {
  restaurants: Restaurant[];
  groups: UserGroup[];
  users: GrantedUser[];
  filter: string | null;
  t: IntlShape["formatMessage"];
}) => {
  const restaurantNamesById = restaurants.reduce<Record<RestaurantId, string>>(
    (acc, r) => ((acc[r.restaurant_id] = r.restaurant_name), acc),
    {},
  );

  // Создаем изначальный массив со всеми ролями, но пока без юзеров
  const initialOptions = groups.reduce(
    (acc, role) => (
      acc.roleIndexMap.set(role.group_name, acc.result.length),
      acc.result.push({
        title:
          role.group_translation_name || getRoleGroupName(role.group_name, t),
        collapseData: new Array(),
      }),
      acc
    ),
    {
      result: new Array<
        CollapsesOption<{
          userUUID: UserUUID;
          userName: string;
          restaurantNames: string;
          phone?: string;
        }>
      >(),
      roleIndexMap: new Map<string, number>(),
    },
  );

  // Наполняем массив с ролями юзерами
  const usersWithRoles = users.reduce((acc, user) => {
    const currentRoleIndex = acc.roleIndexMap.get(user.user_group.group_name);
    invariant(
      currentRoleIndex !== undefined,
      `Unknown role ${user.user_group.group_name}`,
    );
    // Если есть фильтр, то добавить в массив лишь тех пользователей, которые соответствуют фильтру
    (!filter || user.user_profile.fio.toLowerCase().includes(filter)) &&
      acc.result[currentRoleIndex].collapseData.push({
        userUUID: user.user_serial,
        userName: user.user_profile.fio || user.username,
        restaurantNames: getRestaurantNamesString(
          user.active_in_restaurants,
          restaurantNamesById,
        ),
        phone: getPhoneString(
          user.user_profile.extension_phone,
          user.user_profile.phone,
        ),
      });

    return acc;
  }, initialOptions);

  return usersWithRoles.result;
};
