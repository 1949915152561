import dayjs from "dayjs";
import { type Control, useController } from "react-hook-form";
import { useIntl } from "react-intl";
import { ETranslations } from "types/translates";
import { SelectBasic } from "ui-kit";

import type { GuestAndTimeFormSchema } from "./model/schema";
import { getCountSuffix } from "./utils";

const hoursTranslates = {
  one: ETranslations.PLURAL_HOUR,
  two: ETranslations.PLURAL_HOURS_ALT,
  many: ETranslations.PLURAL_HOURS,
};

const minutesTranslates = {
  one: ETranslations.PLURAL_MINUTE,
  two: ETranslations.PLURAL_MINUTES_NOM,
  many: ETranslations.PLURAL_MINUTES,
};

export const BookingDurationSelect = ({
  name,
  label,
  control,
  timeInterval,
}: {
  name: "duration";
  label: string;
  control: Control<GuestAndTimeFormSchema>;
  timeInterval: number;
}) => {
  const { field, fieldState } = useController({
    name,
    control,
    rules: { required: true },
  });
  const { formatMessage } = useIntl();
  const options = Array.from({ length: 48 }, (_, k) => {
    const time = (k + 1) * timeInterval;
    const hours = Math.trunc(time / 60);
    const minutes = time % 60;
    return {
      label: `${hours} ${formatMessage({
        id: getCountSuffix(hours, hoursTranslates),
      }).toLowerCase()} ${minutes}  ${formatMessage({
        id: ETranslations.PLURAL_MINUTES,
      }).toLowerCase()}`,
      value: time,
    };
  });

  return (
    <>
      <input type="hidden" name={name} readOnly />
      <SelectBasic
        required
        label={label}
        options={options}
        onChange={(option) =>
          option && "value" in option && field.onChange(option.value)
        }
        captureMenuScroll
        error={fieldState.error?.message}
      />
    </>
  );
};
