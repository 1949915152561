import { IResponse } from "models/common";
import {
  type GrantedUser,
  GrantedUsersDTO,
  type PermissionByName,
  PermissionDTO,
  type UserCreateRequest,
  UserGroup,
  type UserModifyRequest,
  type UserUUID,
} from "models/user.model";
import { Notification } from "services/notification";

import { ETranslations } from "../../types/translates";
import { api } from "./api";

export const userApi = api
  .enhanceEndpoints({ addTagTypes: ["Users", "User", "Groups"] })
  .injectEndpoints({
    endpoints: (build) => ({
      getAllGroups: build.query<UserGroup[], void>({
        query: () => ({
          url: "users/groups",
        }),
        transformResponse: (response: IResponse<UserGroup[]>) => {
          const result = UserGroup.array().safeParse(response.data);
          !result.success && console.log(result.error.format());
          return result.success ? result.data : response.data;
        },
        providesTags: ["Groups"],
      }),
      getAllUsers: build.query<GrantedUser[], void>({
        query: () => ({
          url: "users/granted",
        }),
        transformResponse: (response: IResponse<GrantedUser[]>) => {
          const result = GrantedUsersDTO.safeParse(response.data);
          !result.success && console.log(result.error.format());
          return result.success ? result.data : response.data;
        },
        providesTags: ["Users"],
      }),
      getUserById: build.query<GrantedUser, UserUUID>({
        query: (userUUID) => ({
          url: `users/${userUUID}`,
          method: "GET",
        }),
        providesTags: (_res, _err, userUUID) => [
          { type: "User", id: userUUID },
        ],
        transformResponse: (response: IResponse<GrantedUser>) => response.data,
      }),
      getPermissions: build.query<PermissionByName, void>({
        query: () => ({
          url: "users/my/permissions",
        }),
        transformResponse: (response: IResponse<PermissionDTO[]>) => {
          const result = PermissionDTO.array().safeParse(response.data);
          !result.success && console.log(result.error.format());
          const permissionsArr = result.success ? result.data : response.data;
          return permissionsArr.reduce<PermissionByName>(
            (acc, permission) => (
              (acc[permission.permission_system_name] = permission), acc
            ),
            {},
          );
        },
        providesTags: ["Groups"],
      }),
      resetPassword: build.mutation<"OK" | "ERROR", UserUUID>({
        query: (userUUID) => ({
          url: `/users/${userUUID}/reset-password`,
          method: "POST",
        }),
        transformResponse: (response: IResponse<"OK" | "ERROR">) =>
          response.data,
      }),
      createUser: build.mutation<GrantedUser, UserCreateRequest>({
        query: (user) => ({
          url: `/users/create`,
          method: "POST",
          body: user,
        }),
        invalidatesTags: ["Users"],
      }),
      modifyUser: build.mutation<GrantedUser, UserModifyRequest>({
        query: ({ userUUID, ...body }) => ({
          url: `/users/${userUUID}/modify`,
          method: "PUT",
          body,
        }),
        invalidatesTags: (_res, _err, args) => [
          "Users",
          { type: "User", id: args.userUUID },
        ],
      }),
      deleteUser: build.mutation<unknown, UserUUID>({
        query: (userUUID) => ({
          url: `/users/${userUUID}`,
          method: "DELETE",
        }),
        invalidatesTags: (_res, _err, userUUID) => [
          "Users",
          { type: "User", id: userUUID },
        ],
      }),
    }),
  });

export const {
  useGetUserByIdQuery,
  useResetPasswordMutation,
  useGetAllUsersQuery,
  useDeleteUserMutation,
  useCreateUserMutation,
  useGetAllGroupsQuery,
  useGetPermissionsQuery,
} = userApi;
