import { getOptionsSettingsMode } from "components/settings/settings-helpers";
import { useAppUsers } from "contexts/AppUsersContext";
import { usePageId } from "contexts/LayoutContext";
import { ModalContext } from "contexts/ModalContext";
import {
  dateSelector,
  restaurantSelector,
  selectedPlacesSelector,
} from "features/AppContext";
import { useGetAllUsersQuery } from "features/api/user-api";
import { useContext } from "react";
import { useSelector } from "react-redux";
import { useCheckPermission } from "services/permissionChecker";

import { useIntlUtils } from "../../hooks/useIntlUtils";

const useSetting = () => {
  const intlUtils = useIntlUtils();
  const {
    settingsParamsChanges,
    settingsUserModal,
    activateModal,
    setConfirmDialogData,
    setSettingsHostessModal,
    setSettingsEditStatusModal,
  } = useContext(ModalContext);

  usePageId("settings");

  const date = useSelector(dateSelector);
  const restaurant = useSelector(restaurantSelector);
  const selectedPlaces = useSelector(selectedPlacesSelector);
  const { userList } = useAppUsers();
  const { data: allUsers } = useGetAllUsersQuery();
  const { hasPermissionFor } = useCheckPermission();
  const settingsOptions = getOptionsSettingsMode().filter(({ permission }) =>
    hasPermissionFor(permission),
  );

  return {
    settingsParamsChanges,
    settingsUserModal,
    activateModal,
    setConfirmDialogData,
    setSettingsHostessModal,
    intlUtils,
    date,
    restaurant,
    selectedPlaces,
    userList,
    allUsers,
    setSettingsEditStatusModal,
    settingsOptions,
  };
};

export default useSetting;
