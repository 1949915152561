import ManagerialModalError from "components/ManagerialTables/form/ManagerialModalError";
import { ConfirmOverbookingModal } from "components/modals/ConfirmOverbookingModal";
import BookingFormReduxProxy from "components/registration/forms/BookingFormReduxProxy";
import React from "react";
import type { LoaderFunction } from "react-router-dom";
import { redirect } from "react-router-dom";
import { getPermissionChecker, useCheckPermission } from "services/permissionChecker";
import { loadStore } from "storage";
import { Button, Card } from "ui-kit";



import { ETranslations } from "../../../../types/translates";
import useCreateBooking from "./hooks/useCreateBooking";


export const loader = (async () => {
  const store = await loadStore();
  const { hasPermissionFor } = await getPermissionChecker(store);

  if (!hasPermissionFor("ACCESS_BOOKING_CREATE", true)) {
    return redirect("/hall-scheme");
  }
  return null;
}) satisfies LoaderFunction;

export const CreateBooking = ({
  reset,
  isFromManagement = false,
  initialFormValues,
}: {
  reset: any;
  isFromManagement?: boolean;
  initialFormValues?: Record<string, any>;
}): React.ReactElement => {
  const fromManagement =
    isFromManagement || initialFormValues?.seatType === "MANAGER_BOOK";
  const {
    isRussianLocale,
    getIntlEntityCreation,
    getIntlAddOf,
    handleChangeMode,
    intl,
    createBooking,
    isConfirmationRequired,
    clearData,
    createWithOver,
    isCreating,
    hasManagerialError,
    close,
    closeModal,
    hasPermissionFor,
  } = useCreateBooking(reset, fromManagement);

  return (
    <Card onClose={close}>
      <Card.Header
        ManagerialButton={
          !fromManagement &&
          hasPermissionFor("ACCESS_MANAGER_TABLES_CREATE") && (
            <Button style={{ padding: 5, marginRight: 12 }} variant="thin">
              <span
                style={{ font: "var(--font-13-r)", fontSize: "400" }}
                onClick={handleChangeMode}
              >
                {intl.formatMessage({ id: ETranslations.MANAGEMENT_BOOKING })}
              </span>
            </Button>
          )
        }
        title={
          isRussianLocale
            ? getIntlEntityCreation(ETranslations.BASE_BOOKINGS)
            : getIntlAddOf(ETranslations.PLURAL_BOOKING)
        }
        needToReset
      />

      <Card.Content noPadding>
        <BookingFormReduxProxy
          initialFormValues={initialFormValues}
          afterSubmit={reset}
          isFromManagement={fromManagement}
          submitFn={createBooking}
        />
        <ConfirmOverbookingModal
          disabled={isCreating}
          isOpen={isConfirmationRequired}
          onConfirm={createWithOver}
          onDecline={clearData}
        />
      </Card.Content>

      {hasManagerialError && (
        <ManagerialModalError
          isOpen={hasManagerialError}
          onClose={closeModal}
        />
      )}
    </Card>
  );
};