import { restaurantsApi } from "features/api/restaurants";
import { userApi } from "features/api/user-api";
import { type LoaderFunction, json } from "react-router-dom";
import { loadStore } from "storage";

export const loader = (({ request }) => {
  loadStore().then((store) => {
    store.dispatch(
      restaurantsApi.util.prefetch("fetchAllRestaurants", undefined, {}),
    );

    store.dispatch(userApi.util.prefetch("getAllGroups", undefined, {}));

    store.dispatch(userApi.util.prefetch("getAllUsers", undefined, {}));
  });

  const filter = new URL(request.url).searchParams.get("q")?.toLowerCase();

  return filter || null;
}) satisfies LoaderFunction;
