import { type LoaderFunction, redirect } from "react-router-dom";
import { getPermissionChecker } from "services/permissionChecker";
import { loadStore } from "storage";


export const createUserLoader = (async () => {
  const store = await loadStore();
  const { hasPermissionFor } = await getPermissionChecker(store);

  if (!hasPermissionFor("FEATURE_MANAGEMENT_ROLE_ASSIGNING", true)) {
    return redirect("/settings/roles");
  }
  return null;
}) satisfies LoaderFunction;