import type { DeepPartial } from "@reduxjs/toolkit";
import { getMaskPhone } from "common/helpers";
import { DefaultErrorBoundary } from "components/ErrorBoundary";
import { config } from "config";
import dayjs from "dayjs";
import { dateSelector } from "features/AppContext";
import type { FormBooking } from "features/BookingFormProxy/types";
import type { Client, ClientId } from "models/client.model";
import { type ReactNode } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useLocation, useOutletContext } from "react-router-dom";
import { useCheckPermission } from "services/permissionChecker";
import { ETranslations } from "types/translates";
import { ICONS, LinkButton } from "ui-kit";

import { GuestBadge } from "./GuestContent/GuestBadge/GuestBadge";
import GuestInfoBigBlock from "./GuestContent/GuestColumn/GuestInfoBigBlock";
import GuestForm from "./GuestContent/GuestForm/GuestForm";
import GuestInfoRow from "./GuestContent/GuestInfoRow/GuestInfoRow";
import { GuestReviews } from "./GuestContent/GuestReviews/GuestReviews";

const GuestContent = ({
  children,
  guest,
}: {
  children?: ReactNode;
  guest: Client;
}) => {
  const { formatMessage, locale } = useIntl();
  const startDate = useSelector(dateSelector);
  const { hasPermissionFor } = useCheckPermission();
  return (
    <>
      <GuestBadge guest={guest} isHideEditPhoto>
        <LinkButton
          variant="primary"
          to="/create-booking"
          disabled={!hasPermissionFor("FEATURE_GUEST_BOOKING_CREATE")}
          state={{
            isBookingFormData: true,
            formData: {
              client: guest,
              phone: guest?.phone,
              phoneMode: Boolean(guest?.phone),
              //@ts-ignore
              date: startDate.toDate(),
              hostess: {},
            } satisfies DeepPartial<FormBooking>,
          }}
        >
          {formatMessage({ id: ETranslations.CREATE_BOOKING })}
        </LinkButton>
        {children}
      </GuestBadge>
      {hasPermissionFor("FEATURE_GUEST_ACCOUNTING") && (
        <GuestInfoRow guest={guest} />
      )}
      <GuestInfoBigBlock
        birthday={guest.birthday && dayjs(guest.birthday).format("DD.MM.YYYY")}
        sex={locale === "ru" ? guest.sex?.name : guest.sex?.nameEng}
        phone={getMaskPhone(guest.phone || "")}
        workPhone={getMaskPhone(guest.work_phone || "")}
        email={guest.email}
        altEmail={guest.alt_email}
        hasLoyaltyProgram={config.BRAND === "WRF"}
        vip={formatMessage({
          id: guest.vip ? ETranslations.BASE_YES : ETranslations.BASE_NO,
        })}
        loyaltyStatus={guest.grade_name}
        comment={guest.comment}
        placeholder={formatMessage({
          id: ETranslations.BASE_NOT_INDICATED,
        })}
      />
      <GuestReviews clientId={guest.client_id as ClientId} />
    </>
  );
};

export const GuestInfo = () => {
  const { search } = useLocation();
  const guest = useOutletContext<Client>();
  const { hasPermissionFor } = useCheckPermission();
  return search.includes("edit") && hasPermissionFor("ACCESS_GUEST_UPDATE") ? (
    <GuestForm guest={guest}>
      <LinkButton to={{ search: undefined }} variant="secondary">
        <ICONS.Edit />
      </LinkButton>
    </GuestForm>
  ) : (
    <GuestContent guest={guest}>
      <LinkButton
        to={{ search: "edit" }}
        variant="secondary"
        disabled={!hasPermissionFor("ACCESS_GUEST_UPDATE")}
      >
        <ICONS.Edit />
      </LinkButton>
    </GuestContent>
  );
};

export const GuestInfoError = () => (
  <DefaultErrorBoundary entity={ETranslations.PLURAL_GUESTS_ALT}>
    {ETranslations.ERROR_ENTITY}
  </DefaultErrorBoundary>
);
