import dayjs from "dayjs";
import { useWebView } from "features/WebView";
import type { ReactNode } from "react";
import type { IntlShape } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import type { FeedContent } from "types/communication";
import { ETranslations } from "types/translates";
import { BottomBar, Button, Card, Spinner } from "ui-kit";
import { useIsTablet } from "ui-kit/hooks";
import { isNonEmptyArray } from "utils";

import { DialogsError } from "../DialogsError";
import { FeedCard } from "./FeedCard";
import styles from "./FeedList.module.scss";
import { FeedListContent } from "./FeedListContent";
import { useFeedList } from "./useFeedList";
import { getStatusIcon, getStatusText } from "./utils";

const ReservationFromDialogButton = ({
  disabled,
  buttonText,
  dialogId,
  clientId,
  restaurantId,
}: {
  disabled: boolean;
  buttonText: string;
  dialogId: string;
  clientId: number;
  restaurantId: number;
}) => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { isWebView, notifyApp, createBackUrl } = useWebView();

  const handleClick = () => {
    if (isWebView) {
      notifyApp({
        type: "CREATE_BOOKING",
        dialogId,
        clientId,
        restaurantId,
        backUrl: createBackUrl(),
      });
    } else {
      navigate({ pathname: "create-booking", search });
    }
  };

  return (
    <Button variant="primary" onClick={handleClick} disabled={disabled}>
      {buttonText}
    </Button>
  );
};

const CardContent = ({
  isLoading,
  isErrorOrEmpty,
  feeds,
  formatMessage,
  errorMessage,
  dialogId,
  restaurantId,
}: {
  isLoading: boolean;
  isErrorOrEmpty: boolean;
  feeds: FeedContent[] | undefined;
  formatMessage: IntlShape["formatMessage"];
  errorMessage: string;
  dialogId: string;
  restaurantId: number;
}) => (
  <Card.Content noPadding>
    {isLoading ? (
      <Spinner />
    ) : isErrorOrEmpty ? (
      <DialogsError
        message={formatMessage(
          feeds && !isNonEmptyArray(feeds)
            ? { id: ETranslations.EMPTY_BOOKINGS }
            : { id: ETranslations.ERROR_ENTITY },
          {
            entity: formatMessage({ id: "PLURAL_BOOKINGS_NOM" }).toLowerCase(),
            error: errorMessage,
          },
        )}
      />
    ) : (
      <FeedListContent feeds={feeds!} formatMessage={formatMessage}>
        {(feed, isBooking, isLinked) => (
          <FeedCard
            date={dayjs(feed.date).format("DD.MM.YY")}
            key={feed.id.feed_id}
            persons={feed.persons}
            restaurantName={feed.partner.partner_name}
            statusText={
              isBooking
                ? formatMessage({
                    id: getStatusText(feed.status_close_state),
                  })
                : formatMessage({ id: ETranslations.ORDER })
            }
            StatusIcon={
              isBooking ? getStatusIcon(feed.status_close_state) : undefined
            }
            time={feed.time.slice(0, 5)}
            feedId={feed.id.feed_id}
            record={`${feed.id.object_type}-${feed.id.object_id}`}
            isLinked={isLinked}
            dialogId={dialogId}
            restaurantId={restaurantId}
          />
        )}
      </FeedListContent>
    )}
  </Card.Content>
);

const FeedsListWrapper = ({
  headerText,
  reservationFromDialog,
  children,
}: {
  headerText: string;
  reservationFromDialog: ReactNode;
  children: ReactNode;
}) => {
  const isTablet = useIsTablet();
  return isTablet ? (
    <>
      {children}
      <BottomBar className={styles.bottomBarTablet}>
        {reservationFromDialog}
      </BottomBar>
    </>
  ) : (
    <Card className={styles.FeedList}>
      <Card.Header controls={reservationFromDialog} title={headerText} />
      {children}
    </Card>
  );
};

export const FeedList = () => {
  const {
    dialogId,
    clientId,
    restaurantId,
    feeds,
    isDialogLoaded,
    isLoading,
    isErrorOrEmpty,
    errorMessage,
    showReservationFromDialogButton,
    formatMessage,
  } = useFeedList();

  return (
    <FeedsListWrapper
      headerText={formatMessage({
        id: ETranslations.BASE_BOOKINGS,
      })}
      reservationFromDialog={
        showReservationFromDialogButton && (
          <ReservationFromDialogButton
            buttonText={formatMessage({
              id: ETranslations.RESERVATION_FROM_DIALOG,
            })}
            disabled={!isDialogLoaded}
            dialogId={dialogId!}
            clientId={Number(clientId)}
            restaurantId={Number(restaurantId)}
          />
        )
      }
    >
      {dialogId && restaurantId && (
        <CardContent
          isLoading={isLoading}
          isErrorOrEmpty={isErrorOrEmpty}
          feeds={feeds}
          formatMessage={formatMessage}
          errorMessage={errorMessage}
          dialogId={dialogId}
          restaurantId={Number(restaurantId)}
        />
      )}
    </FeedsListWrapper>
  );
};
