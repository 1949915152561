import { userApi } from "features/api/user-api";
import { type ActionFunction, redirect } from "react-router-dom";
import { Notification } from "services/notification";
import { loadStore } from "storage";
import { ETranslations } from "types/translates";
import { commonFormErrorMap, formDataToObject } from "utils";

import { UserRoleFormSchema } from "../model/schema";

export const createUserAction = (async ({ request }) => {
  const formDataObj = await request.formData().then(formDataToObject);
  const parsedData = UserRoleFormSchema.safeParse(formDataObj, {
    errorMap: commonFormErrorMap,
  });

  if (!parsedData.success) return parsedData.error.flatten();
  const store = await loadStore();

  const createUser = await store.dispatch(
    userApi.endpoints.createUser.initiate({
      login: parsedData.data.login,
      profile: {
        full_name: parsedData.data.fullName || undefined,
        phone_number: parsedData.data.phone || undefined,
        email: parsedData.data.email || undefined,
        phone_number_ext: parsedData.data.extensionPhone || undefined,
      },
      restaurants_membership: parsedData.data.restaurant,
      access_group_id: parsedData.data.role,
    }),
  );

  if ("error" in createUser || !createUser.data) {
    Notification.error(ETranslations.USER_CREATION_FAILED);
    return {
      formErrors: ETranslations.ERROR_SOMETHING_WENT_WRONG as const,
      fieldErrors: undefined,
    };
  }

  Notification.success(ETranslations.USER_SUCCESSFULLY_CREATED);

  return redirect("/settings/roles");
}) satisfies ActionFunction;
