import { type ReactNode, createContext, useContext } from "react";
import { invariant } from "utils";

type RadioGroupContext = {
  groupName: string;
  defaultValue?: string | number;
  activeValue?: string | number;
  variant?: "flat" | "bordered" | "compact";
  disabledAll?: boolean;
};

const radioGroupContext = createContext<RadioGroupContext | null>(null);

export const useRadioGroupContext = () => {
  const value = useContext(radioGroupContext);
  return (value ||
    invariant(
      value,
      "CreateBookingForm context not passed, please wrap your components with <CreateBookingFormProvider />",
    )) as RadioGroupContext;
};

const RadioGroupContextProvider = ({
  children,
  context,
}: {
  children: ReactNode;
  context: RadioGroupContext;
}) => {
  return (
    <radioGroupContext.Provider value={context}>
      {children}
    </radioGroupContext.Provider>
  );
};

RadioGroupContextProvider.displayName = "RadioGroupContextProvider";

export { RadioGroupContextProvider };
