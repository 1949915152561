import dayjs from "dayjs";
import type { ReactNode } from "react";
import { useIntl } from "react-intl";
import { ETranslations } from "types/translates";
import { RadioGroup } from "ui-kit/RadioButton/RadioButton";

import styles from "./SingleShiftRadioButtons.module.scss";
import { type ButtonParam, serializeShiftData } from "./utils";

const SingleShiftRadioButtons = ({ children }: { children: ReactNode }) => {
  const { formatMessage } = useIntl();

  return (
    <RadioGroup.Root
      variant="bordered"
      groupName="shift"
      label={formatMessage({ id: ETranslations.AVAILABLE_TIME })}
      required
    >
      <RadioGroup.Content>{children}</RadioGroup.Content>
    </RadioGroup.Root>
  );
};

const transformHour = (hour: number) =>
  hour < 10 ? `0${hour}:00` : `${hour}:00`;

const getSingleShiftButtonsParams = ({
  shiftId,
  startDate,
  endDate,
  places,
}: {
  shiftId: number;
  startDate: string;
  endDate: string;
  places: number[];
}): ButtonParam[] => {
  const startShift = dayjs.tz(startDate);
  const endShift = dayjs.tz(endDate);
  const startHour = startShift.hour();
  // Если смена заканчивается не в день startShift, то указываем, что смена до 24 часов этого дня
  const endHour = (startShift.isSame(endShift, "day") && endShift.hour()) || 24;
  const shiftDuration = endHour - startHour;

  if (shiftDuration < 4) {
    return [
      {
        text: `${transformHour(startHour)}-${transformHour(endHour)}`,
        value: serializeShiftData({ shiftId, startHour, endHour, places }),
        defaultChecked: true,
      },
    ];
  }

  const now = dayjs.tz();
  const defaultHour =
    now.isSameOrAfter(startShift) && now.isSameOrBefore(endShift)
      ? now.hour()
      : startHour;

  const hourPerButton = Math.round(shiftDuration / 4);

  const firstButtonStartHour = startHour;
  const firstButtonEndHour = startHour + hourPerButton;
  const firstButtonText = `${transformHour(firstButtonStartHour)}-${transformHour(firstButtonEndHour)}`;

  const secondButtonStartHour = startHour + hourPerButton;
  const secondButtonEndHour = startHour + 2 * hourPerButton;
  const secondButtonText = `${transformHour(secondButtonStartHour)}-${transformHour(secondButtonEndHour)}`;

  const thirdButtonStartHour = startHour + 2 * hourPerButton;
  const thirdButtonEndHour = endHour;
  const thirdButtonText = `${transformHour(thirdButtonStartHour)}-${transformHour(thirdButtonEndHour)}`;
  return [
    {
      text: firstButtonText,
      value: serializeShiftData({
        shiftId,
        startHour: firstButtonStartHour,
        endHour: firstButtonEndHour,
        places,
      }),
      defaultChecked:
        defaultHour >= firstButtonStartHour &&
        defaultHour <= firstButtonEndHour,
    },
    {
      text: secondButtonText,
      value: serializeShiftData({
        shiftId,
        startHour: secondButtonStartHour,
        endHour: secondButtonEndHour,
        places,
      }),
      defaultChecked:
        defaultHour >= secondButtonStartHour &&
        defaultHour <= secondButtonEndHour,
    },
    {
      text: thirdButtonText,
      value: serializeShiftData({
        shiftId,
        startHour: thirdButtonStartHour,
        endHour: thirdButtonEndHour,
        places,
      }),
      defaultChecked:
        defaultHour >= thirdButtonStartHour &&
        defaultHour <= thirdButtonEndHour,
    },
  ];
};

SingleShiftRadioButtons.displayName = "SingleShiftRadioButtons";
export { SingleShiftRadioButtons, getSingleShiftButtonsParams };
