import { Modal } from "components/modal";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { useCheckPermission } from "services/permissionChecker";
import { ETranslations } from "types/translates";
import { Button } from "ui-kit";

import { USER_INTENT } from "../../constants";
import { UserForm } from "../UserForm";
import styles from "../UserForm.module.scss";

const CreateUserModal = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const close = () => navigate("/settings/roles");
  const { hasPermissionFor } = useCheckPermission();
  return (
    <Modal
      className={styles.userModal}
      isOpen
      title={formatMessage({ id: ETranslations.USER_CARD_CREATING })}
      onClose={close}
    >
      <Modal.Content className={styles.modalBody}>
        <UserForm intent={USER_INTENT.CREATE} />
      </Modal.Content>
      <Modal.Footer className={styles.modalFooter}>
        <Button
          disabled={!hasPermissionFor("FEATURE_MANAGEMENT_ROLE_ASSIGNING")}
          name="intent"
          value={USER_INTENT.CREATE}
          form={USER_INTENT.CREATE}
          type="submit"
          variant="primary"
        >
          {formatMessage({ id: ETranslations.BASE_SAVE })}
        </Button>
        <Button type="button" variant="secondary" onClick={close}>
          {formatMessage({ id: ETranslations.BASE_CANCEL })}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

CreateUserModal.displayName = "CreateUserModal";
export { CreateUserModal };
