import dayjs from "dayjs";
import { useFetchWeekShiftsQuery } from "features/api/shifts";
import { useIntl } from "react-intl";
import type { WeekShift } from "types/shift";
import { ETranslations } from "types/translates";

export const ShiftRadioGroup = ({
  restaurantId,
  date,
  LoadingComponent,
  ErrorComponent,
  children,
}: {
  restaurantId: number;
  date: string;
  LoadingComponent: React.ComponentType;
  ErrorComponent: React.ComponentType<{ error: string }>;
  children: (shifts: WeekShift[], defaultShiftId: number) => JSX.Element;
}) => {
  const { formatMessage } = useIntl();
  const { data, isLoading, error } = useFetchWeekShiftsQuery({
    restaurantId: restaurantId,
    start_date: date,
    end_date: date,
  });

  if (isLoading) return <LoadingComponent />;

  if (!data?.length)
    return (
      <ErrorComponent
        error={JSON.stringify(
          error || formatMessage({ id: ETranslations.BASE_NO_VALUES }),
        )}
      />
    );

  const now = dayjs.tz();
  const defaultShift =
    data.find(
      (shift) =>
        dayjs.tz(shift.start_date).isSameOrBefore(now) &&
        dayjs.tz(shift.end_date).isSameOrAfter(now),
    ) || data[0];

  return children(data, defaultShift.id);
};
