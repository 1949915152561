import React, { lazy } from "react";
import { TypeModal } from "types/rootState";
import { Spinner } from "ui-kit";

import useSetting from "./useSetting";

const SettingsComponent = lazy(() => import("components/settings/settings"));

export function Settings() {
  const {
    settingsParamsChanges,
    settingsUserModal,
    activateModal,
    setConfirmDialogData,
    setSettingsHostessModal,
    intlUtils,
    date,
    restaurant,
    selectedPlaces,
    userList,
    allUsers,
    setSettingsEditStatusModal,
    settingsOptions,
  } = useSetting();

  return (
    <React.Suspense fallback={<Spinner />}>
      <SettingsComponent
        allUsers={allUsers}
        date={date}
        intlUtils={intlUtils}
        places={selectedPlaces}
        rest={restaurant.restaurant_id}
        userList={userList}
        settingsOptions={settingsOptions}
        onCallModalConfirm={(id, mode) => {
          setConfirmDialogData({ data: id, mode });
          activateModal(TypeModal.confirm, "guest-card");
        }}
        onCallModalHostess={(hostess, restId) => {
          setSettingsHostessModal({ hostess, restId });
          activateModal(TypeModal.changeHostess, "guest-card");
        }}
        onCallModalParamsChange={(params) => {
          Object.assign(settingsParamsChanges, params);
          activateModal(TypeModal.changesParams, "guest-card");
        }}
        onCallModalStatus={(allStatuses, statusId, editStatus, isEditing) => {
          setSettingsEditStatusModal({
            allStatuses,
            statusId,
            editStatus,
            isEditing,
          });
          activateModal(TypeModal.status, "guest-card");
        }}
        onCallModalUser={(user, role) => {
          Object.assign(settingsUserModal, {
            user: user ? { ...user } : undefined,
            role,
          });
          activateModal(TypeModal.changeUser, "guest-card");
        }}
      />
    </React.Suspense>
  );
}
