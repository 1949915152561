import { AuthService } from "services/auth.service";
import { AppTokenResponse, WebkitMessageHandler } from "./types";

export function createMockHandler(authService: typeof AuthService) {
    let handler = (<any>window).webkit?.messageHandlers?.hostesApp as WebkitMessageHandler | undefined;
    if (!handler) {

        handler = {
            async postMessage(msg) {
                if (msg.type === 'AUTH_REQUEST_TOKEN') {
                    const { requestId } = msg;
                    const user = authService.getUser();
                    const access = await authService.getAccessToken();
                    
                    if (!access) {
                        // eslint-disable-next-line no-console
                        console.log('WebView Mock. ERROR: no access token');
                        return;
                    }

                    const response: AppTokenResponse = {
                        requestId,
                        type: "AUTH_TOKEN",
                        token: access.token,
                        expDateMs:access.expDateMs,
                        user: {
                            id:  0,
                            name: '',
                            role: ''
                        },
                    };

                    (<any>window).__hostesAppCallback(response);
                }
            }
        };

        (<any>window).webkit = {
            messageHandlers: {
                hostesApp: handler
            }
        }
    }
}
