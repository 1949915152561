import { DefaultErrorBoundary } from "components/ErrorBoundary";
import { Modal } from "components/modal";
import type { UserUUID } from "models/user.model";
import { Suspense } from "react";
import { useIntl } from "react-intl";
import { Await, useLoaderData, useNavigate } from "react-router-dom";
import { useCheckPermission } from "services/permissionChecker";
import { ETranslations } from "types/translates";
import { Button, Spinner } from "ui-kit";

import { userInfoLoader } from "../../api/userInfoLoader";
import { USER_INTENT } from "../../constants";
import { UserForm } from "../UserForm";
import styles from "../UserForm.module.scss";
import { ResetPasswordModal } from "./ResetPasswordModal";

const EditUserModal = () => {
  const { formatMessage } = useIntl();
  const { userUUID, userData } = useLoaderData<typeof userInfoLoader>();
  const { hasPermissionFor } = useCheckPermission();
  const navigate = useNavigate();
  const close = () => navigate("/settings/roles");

  return (
    <Modal
      className={styles.userModal}
      isOpen
      title={formatMessage({ id: ETranslations.USER_CARD_UPDATING })}
      onClose={close}
    >
      <Modal.Content className={styles.modalBody}>
        <Suspense fallback={<Spinner />}>
          <Await
            resolve={userData}
            errorElement={
              <DefaultErrorBoundary entity={ETranslations.OF_USER}>
                {ETranslations.ERROR_ENTITY}
              </DefaultErrorBoundary>
            }
          >
            {(data) => (
              <UserForm
                intent={USER_INTENT.EDIT}
                fullName={data.user_profile.fio}
                extensionPhone={data.user_profile.extension_phone || undefined}
                email={data.user_profile.email || undefined}
                phone={data.user_profile.phone || undefined}
                restaurants={data.active_in_restaurants}
                roleId={data.user_group.id}
                login={data.username}
              >
                <ResetPasswordModal
                  // FIXME: убрать as после апдгрейда typescript
                  userUUID={userUUID as UserUUID}
                  onResetPassword={close}
                />
              </UserForm>
            )}
          </Await>
        </Suspense>
      </Modal.Content>
      <Modal.Footer className={styles.modalFooter}>
        <Button
          name="intent"
          value={USER_INTENT.EDIT}
          form={USER_INTENT.EDIT}
          type="submit"
          variant="primary"
          disabled={!hasPermissionFor("FEATURE_MANAGEMENT_ROLE_ASSIGNING")}
        >
          {formatMessage({ id: ETranslations.BASE_SAVE })}
        </Button>
        <Button type="button" variant="secondary" onClick={close}>
          {formatMessage({ id: ETranslations.BASE_CANCEL })}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

EditUserModal.displayName = "EditUserModal";
export { EditUserModal };
