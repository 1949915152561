import cn from "classnames";
import { BookingCardViewProps } from "components/BookingCard";
import {
  DashboardBookingCard,
  HallBookingCard,
} from "components/BookingCard/DashboardBookingCard";
import { GlobalSearchBookingCard } from "components/BookingCard/GlobalSearchCard";
import { DashboardManagementCard } from "components/BookingCard/ManagementCard";
import type { BookingSlot, Slot } from "models/booking.model";
import { useIntl } from "react-intl";
import { useLocation, useNavigation } from "react-router-dom";
import { Spinner } from "ui-kit";

import { ETranslations } from "../../types/translates";
import styles from "./BookingList.module.scss";

export interface BookingsListProps<T extends Slot>
  extends BookingCardViewProps {
  bookings: T[];
  className?: string;
  emptyMessage?: string;
  loading?: boolean;
  allowMove?: boolean;
  canDrag?: boolean;
}

export const EmptyBookingList = ({
  message,
  loading,
  className,
}: {
  message?: string;
  loading?: boolean;
  className?: string;
}) => {
  const { formatMessage } = useIntl();
  return (
    <section className={cn(styles.list, className)}>
      <div className={styles.blank}>
        {message || formatMessage({ id: ETranslations.BOOKING_LIST_EMPTY })}
        {loading && <Spinner className={styles.spinner} />}
      </div>
    </section>
  );
};

//TODO: разделить список для дашборда и схемы зала
export const BookingsList = <T extends Slot>({
  bookings,
  className,
  emptyMessage,
  loading,
}: BookingsListProps<T>) => {
  const navigation = useNavigation();
  const { pathname } = useLocation();
  const isHallScheme = pathname.includes("/hall-scheme");

  if (!bookings.length) {
    return (
      <EmptyBookingList
        message={emptyMessage}
        loading={loading}
        className={className}
      />
    );
  }

  return (
    <div className={cn(styles.list, className)}>
      {isHallScheme
        ? bookings.map((b) => {
            if (b.slot_type === "BOOKING")
              return <HallBookingCard key={b.slot_id} booking={b} />;
            if (b.slot_type === "MANAGER")
              return (
                <DashboardManagementCard
                  //@ts-ignore
                  key={b.slot_id}
                  management={b}
                />
              );
          })
        : bookings.map((b) => {
            if (b.slot_type === "BOOKING")
              return <DashboardBookingCard key={b.slot_id} booking={b} />;
            if (b.slot_type === "MANAGER")
              return (
                <DashboardManagementCard
                  //@ts-ignore
                  key={b.slot_id}
                  management={b}
                />
              );
          })}
      {(navigation.state === "loading" || loading) && (
        <Spinner className={styles.spinner} />
      )}
    </div>
  );
};

export const GlobalSearchBookingList = ({
  bookings,
  loading,
  onClick,
}: {
  bookings: BookingSlot[];
  loading: boolean;
  onClick: (book: BookingSlot) => void;
}) => {
  const navigation = useNavigation();
  return (
    <ul className={styles.list}>
      {bookings.map((b) => (
        <li key={b.slot_id}>
          <GlobalSearchBookingCard booking={b} onClick={onClick} />
        </li>
      ))}
      {(navigation.state === "loading" || loading) && (
        <Spinner className={styles.spinner} />
      )}
    </ul>
  );
};
