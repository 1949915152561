import cn from "classnames";
import React, { type ElementType, ReactNode } from "react";

import styles from "./Modal.module.scss";

interface ModalContentProps {
  children: ReactNode;
  className?: string;
  noPadding?: boolean;
  as?: ElementType;
}

export function ModalContent({
  children,
  className,
  noPadding,
  as,
}: ModalContentProps) {
  const Element = as || "div";
  return (
    <Element
      className={cn(styles.modalContent, className, {
        [styles.noPadding]: noPadding,
      })}
    >
      {children}
    </Element>
  );
}
