import {
  useDeleteShiftMutation,
  useFetchShiftQuery,
} from "features/api/shifts";
import React, { FC, useCallback, useState } from "react";
import { useIntl } from "react-intl";
import { useCheckPermission } from "services/permissionChecker";
import { TNullable } from "types/commons";
import { ShiftModalType } from "types/shift";
import { ETranslations } from "types/translates";
import { Button, ICONS } from "ui-kit";

import { Modal, ModalProps } from "../../../modal";
import { EditFormContainer } from "../../Form/EditFormContainer/EditFormContainer";
import { BaseShiftsModal } from "../BaseShiftsModal";
import styles from "../style.module.scss";
import { InfoShiftsModalBody } from "./InfoShiftsModalBody";

type Props = {
  shiftId: TNullable<number>;
} & Omit<ModalProps, "children">;

export const InfoShiftsModal: FC<Props> = ({
  isOpen,
  onClose,
  title,
  shiftId,
}) => {
  if (!shiftId) return null;
  const parentSelector = document.querySelector("#settings");
  if (!parentSelector) return null;

  const { data: shiftResponse, isLoading } = useFetchShiftQuery(shiftId);
  const [deleteShift, { isLoading: deleteIsLoading }] =
    useDeleteShiftMutation();
  const { hasPermissionFor } = useCheckPermission();
  const intl = useIntl();
  const [view, setView] = useState<ShiftModalType>("INFO");

  const deleteShiftHandler = useCallback(
    () => deleteShift(shiftId).then(onClose),
    [deleteShift, shiftId],
  );

  const editShiftHandler = useCallback(() => {
    !isLoading && setView("EDIT");
  }, [isLoading]);

  return (
    <BaseShiftsModal
      isOpen={isOpen}
      title={title}
      onClose={view === "EDIT" ? () => setView("INFO") : onClose}
    >
      {view === "INFO" && (
        <>
          <Modal.Content className={styles.modalContent}>
            <InfoShiftsModalBody
              isLoading={isLoading || deleteIsLoading}
              shift={shiftResponse}
            />
          </Modal.Content>
          <Modal.Footer className={styles.modalFooter}>
            <Button
              disabled={
                isLoading || !hasPermissionFor("ACCESS_MANAGEMENT_SHIFT_DELETE")
              }
              variant="secondary"
              onClick={deleteShiftHandler}
            >
              {intl.formatMessage({ id: ETranslations.BASE_DELETE })}
            </Button>
            <Button
              disabled={
                isLoading || !hasPermissionFor("ACCESS_MANAGEMENT_SHIFT_UPDATE")
              }
              variant="primary"
              onClick={editShiftHandler}
            >
              <ICONS.Edit />
            </Button>
          </Modal.Footer>
        </>
      )}
      {view === "EDIT" && (
        <EditFormContainer setView={setView} shift={shiftResponse} />
      )}
    </BaseShiftsModal>
  );
};
