import type { RestaurantId } from "models/booking.model";
import { UserGroupId } from "models/user.model";
import { ETranslations } from "types/translates";
import { z } from "zod";

import { USER_INTENT } from "../constants";

export const UserRoleFormSchema = z.object({
  intent: z.enum([USER_INTENT.CREATE, USER_INTENT.EDIT]),
  email: z
    .union([
      z.string().trim().pipe(z.literal("")),
      z
        .string()
        .email({ message: ETranslations.FIELD_INVALID })
        .max(50, ETranslations.FIELD_TOO_LONG),
    ])
    .optional(),
  fullName: z.string().trim().min(1, ETranslations.FIELD_CANT_BE_EMPTY),
  phone: z.union([
    z.string().trim().pipe(z.literal("")),
    z
      .string()
      .trim()
      .regex(/^\+?[1-9]\d{1,14}$/, ETranslations.FIELD_INVALID)
      .optional(),
  ]),
  extensionPhone: z.union([
    z.string().trim().pipe(z.literal("")),
    z
      .string()
      .trim()
      .regex(/^\+?[1-9]\d{1,14}$/, ETranslations.FIELD_INVALID)
      .optional(),
  ]),
  restaurant: z
    .preprocess(
      (val) =>
        Array.isArray(val)
          ? val
          : typeof val === "string" && val.length
            ? [val]
            : undefined,
      z.string().array().nonempty(),
    )
    .transform((arr) =>
      arr.map((stringId) => Number(stringId) as RestaurantId),
    ),
  login: z
    .string()
    .trim()
    .min(3, ETranslations.FIELD_INVALID)
    .max(50, ETranslations.FIELD_TOO_LONG)
    .toLowerCase(),
  role: z.coerce.number().pipe(UserGroupId),
});
