import { ICONS } from "common/helpers";
import { ConfirmBusyTable } from "components/ConfirmBusyTable";
import { ConfirmModal } from "components/ConfirmModal";
import { Menu } from "components/Menu/Menu";
import { Popup, PopupProps } from "components/Popup";
import { Modal } from "components/modal";
import { config } from "config";
import dayjs from "dayjs";
import type { BookingSlot, ManagerSlot } from "models/booking.model";
import { useCallback, useMemo } from "react";
import { useCheckPermission } from "services/permissionChecker";
import { Button, Spinner } from "ui-kit";
import { isBookingCanBeCanceled, isManagerialTable } from "utils";

import { ETranslations } from "../../types/translates";
import styles from "./BookingActions.module.scss";
import { CancelActions, CancelActionsContainer } from "./CancelActions";
import useBookingActions from "./hooks/useBookingActions";

const RESET_PADDING = { top: true, bottom: true };

type BookingActionsProps = {
  className?: string;
  booking: BookingSlot | ManagerSlot;
  onEdit?: (booking: BookingSlot | ManagerSlot) => void;
  editUrl?: string;
  allowMove?: boolean;
} & Pick<PopupProps, "placement">;

export function BookingActions({
  className,
  booking,
  onEdit,
  editUrl,
  allowMove,
  placement = "auto-start",
}: BookingActionsProps) {
  const {
    isLoading,
    isCancel,
    handleEdit,
    intl,
    isManagerialTableAndTimePassed,
    createBooking,
    cancel,
    handleMoveClick,
    setTimestamp,
    nextStatus,
    handleStatusChange,
    recovery,
    handlePrint,
    isPrintDisabled,
    show,
    hide,
    popup,
    setWasOpen,
    force,
    goNextStatus,
    resetForce,
    isFloorplan,
    managerialFormBookingState,
    isOpenConfirmStatusChangeModal,
    openConfirmStatusChangeModal,
    closeConfirmStatusChangeModal,
    from,
  } = useBookingActions(booking, onEdit);
  const { hasPermissionFor } = useCheckPermission();

  const popupContent = useMemo(() => {
    if (isLoading) return <Spinner />;
    if (
      isCancel &&
      !isManagerialTable(booking) &&
      hasPermissionFor("ACCESS_BOOKING_STATUS_OPERATIONS")
    )
      return (
        <CancelActionsContainer back={hide}>
          <CancelActions
            booking={booking}
            close={popup.current?.close}
            className={styles.cancelContentM}
          />
        </CancelActionsContainer>
      );
    return (
      <Menu className={styles.menu}>
        {(onEdit || editUrl) && hasPermissionFor("ACCESS_BOOKING_UPDATE") && (
          <Menu.Item
            icon={ICONS.Edit}
            onClick={handleEdit}
            as="link"
            to={editUrl || "."}
          >
            {intl.formatMessage({ id: ETranslations.BASE_EDIT })}
          </Menu.Item>
        )}

        {isManagerialTable(booking) &&
          !isManagerialTableAndTimePassed &&
          hasPermissionFor("ACCESS_MANAGER_TABLES_SEATING") && (
            <Menu.Item
              icon={ICONS.Plus}
              onClick={createBooking}
              as={"link"}
              to={"/create-booking"}
              state={{
                isBookingFormData: true,
                formData: managerialFormBookingState,
                from,
              }}
            >
              {intl.formatMessage({ id: ETranslations.CREATE_BOOKING })}
            </Menu.Item>
          )}

        {isManagerialTable(booking) &&
          hasPermissionFor("FEATURE_MANAGEMENT_TABLES") && (
            <Menu.Item
              className={styles.cancelContentM}
              icon={ICONS.Cancel}
              onClick={async (e) => {
                e.stopPropagation();
                cancel({ id: booking.slot_id });
              }}
            >
              <span>
                {intl.formatMessage({ id: ETranslations.BASE_CANCEL })}
              </span>
            </Menu.Item>
          )}
        {allowMove &&
          isFloorplan &&
          !isManagerialTable(booking) &&
          hasPermissionFor("FEATURE_BOOKING_PLACE_TRANSFER") && (
            <Menu.Item
              icon={ICONS.Move}
              onClick={(e) => {
                e.stopPropagation();
                handleMoveClick();
                const dateOfEdit = dayjs(
                  `${booking.date} ${booking.time}`,
                ).valueOf();
                setTimestamp(dateOfEdit);
              }}
            >
              {intl.formatMessage({ id: ETranslations.BASE_RESEAT })}
            </Menu.Item>
          )}
        {nextStatus &&
          !isManagerialTable(booking) &&
          hasPermissionFor("ACCESS_BOOKING_STATUS_OPERATIONS") && (
            <Menu.Item
              onClick={() =>
                nextStatus.system_name === "IN_HALL" &&
                dayjs(`${booking.date} ${booking.time}`).isAfter(dayjs())
                  ? (openConfirmStatusChangeModal(), popup.current?.close())
                  : handleStatusChange()
              }
              icon={ICONS.Check}
            >
              {nextStatus.name}
            </Menu.Item>
          )}
        {!isManagerialTable(booking) &&
          hasPermissionFor("ACCESS_BOOKING_STATUS_OPERATIONS") && (
            <Menu.Item
              icon={ICONS.Recovery}
              onClick={(e) => {
                e.stopPropagation();
                recovery();
              }}
            >
              {intl.formatMessage({ id: ETranslations.BASE_RESTORE })}
            </Menu.Item>
          )}
        {config.isPrintAvailable && !isManagerialTable(booking) && (
          <Menu.Item
            onClick={(e) => {
              e.stopPropagation();
              handlePrint();
            }}
            icon={ICONS.Printer}
            disabled={isPrintDisabled}
          >
            {intl.formatMessage({ id: ETranslations.BASE_PRINT })}
          </Menu.Item>
        )}
        {!isManagerialTable(booking) &&
          isBookingCanBeCanceled(booking) &&
          hasPermissionFor("ACCESS_BOOKING_STATUS_OPERATIONS") &&
          (config.BRAND === "DUBAI" ? (
            <CancelActions
              booking={booking}
              close={popup.current?.close}
              className={styles.cancelContentM}
            />
          ) : (
            <Menu.Item
              onClick={(e) => {
                e.stopPropagation();
                show();
              }}
              icon={ICONS.Cancel}
              className={styles.cancel}
            >
              <span className={styles.cancelContent}>
                {intl.formatMessage({ id: ETranslations.BASE_CANCEL })}
                <ICONS.NextIcon />
              </span>
            </Menu.Item>
          ))}
      </Menu>
    );
  }, [
    isLoading,
    isCancel,
    hide,
    booking,
    onEdit,
    handleEdit,
    allowMove,
    handleMoveClick,
    nextStatus,
    handleStatusChange,
    recovery,
    show,
  ]);

  return (
    <>
      <Popup
        placement={placement}
        ref={popup}
        onClose={hide}
        onOpen={setWasOpen}
      >
        <Popup.Trigger>
          <Button className={className} variant="phantom">
            <ICONS.ActionsMenu />
          </Button>
        </Popup.Trigger>
        <Popup.Content noPadding={RESET_PADDING}>{popupContent}</Popup.Content>
      </Popup>
      <ConfirmModal
        isOpen={isOpenConfirmStatusChangeModal}
        header={intl.formatMessage({
          id: ETranslations.CHANGE_RESERVATION_CONFIRM_HEADER,
        })}
        confirm={handleStatusChange}
        close={closeConfirmStatusChangeModal}
      >
        {intl.formatMessage({
          id: ETranslations.CHANGE_STATUS_CONFIRM_MESSAGE,
        })}
      </ConfirmModal>
      <ConfirmBusyTable
        confirm={goNextStatus}
        decline={resetForce}
        isOpen={force}
      />
    </>
  );
}
