import { useEffect, useState } from "react";
import { type Control, useController } from "react-hook-form";
import { useIntl } from "react-intl";
import { ETranslations } from "types/translates";
import { SelectBasic } from "ui-kit";

import styles from "./GuestAndTimeStep.module.scss";
import type { GuestAndTimeFormSchema } from "./model/schema";
import { getCountSuffix } from "./utils";

const MIN_GUEST_CUSTOM_INPUT_VALUE = 7;

const translates = {
  one: ETranslations.PLURAL_GUEST,
  two: ETranslations.PLURAL_GUESTS_ALT,
  many: ETranslations.PLURAL_GUESTS,
};

export const GuestCountSelect = ({
  name,
  control,
}: {
  name: "guests";
  control: Control<GuestAndTimeFormSchema>;
}) => {
  const { formatMessage } = useIntl();

  const { field } = useController({
    name,
    control,
    rules: { required: true },
  });
  const defaultState = Array.from({ length: 24 }, (_, k) => ({
    label: `${k + MIN_GUEST_CUSTOM_INPUT_VALUE} ${formatMessage({
      id: getCountSuffix(k + MIN_GUEST_CUSTOM_INPUT_VALUE, translates),
    }).toLowerCase()}`,
    value: k + MIN_GUEST_CUSTOM_INPUT_VALUE,
  }));

  const [options, setOptions] = useState(defaultState);
  return (
    <SelectBasic
      className={styles.guestCountSelect}
      options={options}
      value={{
        label: `${field.value} ${formatMessage({
          id: getCountSuffix(field.value, translates),
        }).toLowerCase()}`,
        value: field.value,
      }}
      onChange={(option) =>
        option && "value" in option && field.onChange(String(option.value))
      }
      onBlur={field.onBlur}
      onMenuScrollToBottom={() =>
        setOptions((prev) =>
          Array.from({ length: prev.length + 12 }, (_, k) => ({
            label: `${k + MIN_GUEST_CUSTOM_INPUT_VALUE} ${formatMessage({
              id: getCountSuffix(k + MIN_GUEST_CUSTOM_INPUT_VALUE, translates),
            }).toLowerCase()}`,
            value: k + MIN_GUEST_CUSTOM_INPUT_VALUE,
          })),
        )
      }
      onMenuScrollToTop={() => setOptions(defaultState)}
      captureMenuScroll
    />
  );
};
