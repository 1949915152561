import { useCheckPermission } from "services/permissionChecker";
import { ICONS } from "ui-kit";

import styles from "./GuestNotes.module.scss";

export const Actions = ({
  className,
  editNote,
}: {
  className: string;
  editNote: () => void;
}) => {
  const { hasPermissionFor } = useCheckPermission();
  return (
    <section className={className}>
      {!hasPermissionFor("ACCESS_GUEST_NOTES_UPDATE") && (
        <button onClick={editNote}>
          <ICONS.Edit className={styles.action} />
        </button>
      )}
      {/*  <div className={styles.divider} />
    <button onClick={deleteNote}>
      <ICONS.TrashBin className={styles.action} />
    </button> */}
    </section>
  );
};
