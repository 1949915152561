import cn from "classnames";
import {
  Children,
  type FormEvent,
  ReactElement,
  ReactNode,
  cloneElement,
  forwardRef,
  isValidElement,
  useMemo,
} from "react";
import { FieldError } from "ui-kit/FieldError";

import { Button, InternalButtonProps } from "./Button";
import styles from "./RadioButton.module.scss";
import {
  RadioGroupContextProvider,
  useRadioGroupContext,
} from "./RadioGroupContext";

export interface RadioButtonProps<T> {
  children: ReactNode;
  value: T;
  onChange: (next: T) => void;
  disabled?: boolean;
  className?: string;
}

function filterButton<T>(
  v: ReactNode,
): v is ReactElement<InternalButtonProps<T>> {
  return isValidElement(v) && v.type === Button;
}

export function RadioButton<T>({
  children,
  value,
  onChange,
  disabled,
  className,
}: RadioButtonProps<T>) {
  const options = useMemo(
    () =>
      Children.toArray(children)
        .filter(filterButton)
        .map((button) =>
          cloneElement(button, {
            onClick: () => onChange(button.props.value as T),
            className: cn(button.props.className, {
              [styles.active]: value === button.props.value,
            }),
            disabled: button.props.disabled ?? disabled,
          }),
        ),
    [children, value, onChange, disabled],
  );
  return <div className={cn(styles.radio, className)}>{options}</div>;
}

const RadioGroupRoot = ({
  variant,
  groupName,
  defaultValue,
  activeValue,
  label,
  onChange,
  children,
  required,
  disabled,
  className,
  labelClassName,
  error,
}: {
  variant?: "flat" | "bordered" | "compact";
  groupName: string;
  defaultValue?: string;
  activeValue?: string;
  label?: string;
  onChange?: (value: string) => void;
  children: React.ReactNode;
  required?: boolean;
  disabled?: boolean;
  className?: string;
  labelClassName?: string;
  error?: string | string[];
}) => {
  const handleRadioChange =
    onChange &&
    ((e: React.FormEvent<HTMLFieldSetElement>) => {
      if (e.target instanceof HTMLInputElement) {
        onChange(e.target.value);
      }
    });

  return (
    <fieldset
      className={cn(styles.radioGroup, className)}
      onChange={handleRadioChange}
    >
      {label && (
        <legend
          className={cn(
            styles.label,
            required && styles.required,
            labelClassName,
          )}
        >
          {label}
        </legend>
      )}
      <RadioGroupContextProvider
        context={{
          variant,
          groupName,
          defaultValue,
          activeValue,
          disabledAll: disabled,
        }}
      >
        {children}
      </RadioGroupContextProvider>
      {error && <FieldError error={error} fieldName={label} />}
    </fieldset>
  );
};

const RadioGroupContent = forwardRef<
  HTMLDivElement,
  {
    children: React.ReactNode;
    className?: string;
  }
>(({ children, className }, ref) => {
  const { variant } = useRadioGroupContext();

  return (
    <div
      ref={ref}
      className={cn(styles.buttons, styles[variant || "flat"], className)}
    >
      {children}
    </div>
  );
});

const RadioGroupButton = forwardRef<
  HTMLInputElement,
  {
    id?: string | number;
    value: string | number;
    disabled?: boolean;
    children?: ReactNode;
    className?: string;
    anchor?: string;
  } & React.InputHTMLAttributes<HTMLInputElement>
>(({ id, value, disabled, children, className, anchor, ...rest }, ref) => {
  const { variant, groupName, defaultValue, activeValue, disabledAll } =
    useRadioGroupContext();

  const inputId = `${groupName}-${id ?? value}`;

  return (
    <>
      <input
        id={inputId}
        // Будет перезаписан, если отправлен новый name в ...rest
        name={groupName}
        value={value}
        // Будет перезаписан, если отправлен новый defaultChecked в ...rest
        defaultChecked={value === defaultValue || undefined}
        // Будет перезаписан, если отправлен новый checked в ...rest
        checked={value === activeValue || undefined}
        disabled={disabled || disabledAll}
        type="radio"
        hidden
        ref={ref}
        {...rest}
      />
      <label
        id={anchor}
        htmlFor={inputId}
        className={cn(styles.radioButton, styles[variant || "flat"], className)}
      >
        {children ?? value}
      </label>
    </>
  );
});

RadioGroupRoot.displayName = "RadioGroup.Root";
RadioGroupContent.displayName = "RadioGroup.Content";
RadioGroupButton.displayName = "RadioGroup.Button";

export const RadioGroup = {
  Root: RadioGroupRoot,
  Content: RadioGroupContent,
  Button: RadioGroupButton,
};
