import { useGetPermissionsQuery, userApi } from "features/api/user-api";
import type { PermissionByName, PermissionName } from "models/user.model";
import { useMemo } from "react";
import { Notification } from "services/notification";
import { type AppStore } from "storage";
import { ETranslations } from "types/translates";

export type PermissionChecker = (
  permissionName: PermissionName,
  toastOnFalse?: boolean,
) => boolean;

// Фабрика для создания проверяющего объекта
const createChecker = (
  permissions: PermissionByName,
): { hasPermissionFor: PermissionChecker } => ({
  // создаем функцию проверки
  hasPermissionFor: (
    permissionName: PermissionName,
    toastOnFalse?: boolean,
  ) => {
    // получаем boolean значение есть нужное разрешение у пользователя или нет
    const hasPermission = !!permissions[permissionName];

    // тост, об отсутствии разрешения, если надо
    if (toastOnFalse && !hasPermission) {
      Notification.error({
        title: (t) => t({ id: ETranslations.PERMISSION_DENIED }),
        message: (t) =>
          t({ id: ETranslations.PERMISSION_CODE }, { value: permissionName }),
      });
    }
    // возвращаем boolean значение для дальнейшей работы с ним
    return hasPermission;
  },
});

// Асинхронная загрузка разрешений через store для loader/action
export const getPermissionChecker = (store: AppStore) =>
  store
    .dispatch(userApi.endpoints.getPermissions.initiate())
    .unwrap()
    .then((permissions) => createChecker(permissions || {})) // Если нет данных, используем пустой объект
    .catch((error) => {
      Notification.error({
        title: (t) => t({ id: ETranslations.ERROR_SOMETHING_WENT_WRONG }),
        message: error?.message || JSON.stringify(error),
      });
      // Уходим в ErrorBoundary
      throw error;
    });

// Хук для работы с разрешениями для компонентов
export const useCheckPermission = () => {
  const { data } = useGetPermissionsQuery();

  // Если данных нет, используем пустой объект (все разрешения будут false)
  // Поэтому не рекомендуется использовать внутри useEffect для каких-то автоматических действий
  const checker = useMemo(() => createChecker(data || {}), [data]);

  return checker;
};
