import cn from "classnames";
import { ButtonHTMLAttributes, forwardRef } from "react";
import { Link, type LinkProps } from "react-router-dom";

import styles from "./Button.module.scss";

interface ButtonVariants {
  variant:
    | "primary"
    | "secondary"
    | "dangerous"
    | "status"
    | "light"
    | "thin"
    | "phantom";
  square?: boolean;
}

export interface ButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement>,
    ButtonVariants {}
export interface ButtonLinkProps extends LinkProps, ButtonVariants {
  disabled?: boolean;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ type = "button", variant, className, square, ...props }, ref) => (
    <button
      {...props}
      className={cn(styles.button, styles[variant], className, {
        [styles.square]: square,
      })}
      ref={ref}
      type={type}
    />
  ),
);

export const LinkButton = forwardRef<HTMLAnchorElement, ButtonLinkProps>(
  ({ to, variant, className, square, children, disabled, ...props }, ref) => (
    <Link
      to={to}
      {...props}
      aria-disabled={disabled}
      className={cn(styles.button, styles.link, styles[variant], className, {
        [styles.square]: square,
      })}
      ref={ref}
    >
      {children}
    </Link>
  ),
);
