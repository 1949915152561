import { LocalesSchema } from "models/common";
import { ETranslations } from "types/translates";
import { zDiscriminatedUnion } from "utils";
import { z } from "zod";

import { AUTH_INTENT } from "../constants";

const EntryForm = z.object({
  intent: z.literal(AUTH_INTENT.ENTRY, { message: "Invalid intent" }),
  // дополнительная валидация, что redirect планируется на внутренний ресурс
  redirectTo: z
    .string()
    .optional()
    .transform((path) => (path && /^\/\w+/.test(path) ? path : "/")),
  locale: LocalesSchema,
  tenant: z.string(),
  login: z
    .string()
    .trim()
    .min(1, { message: ETranslations.FIELD_CANT_BE_EMPTY }),
  password: z
    .string()
    .trim()
    .min(1, { message: ETranslations.FIELD_CANT_BE_EMPTY }),
});

const ConfirmForm = EntryForm.extend({
  intent: z.literal(AUTH_INTENT.CONFIRM, { message: "Invalid intent" }),
  newPassword: z
    .string()
    .trim()
    .min(1, { message: ETranslations.FIELD_CANT_BE_EMPTY }),
  confirmPassword: z.string(),
}).superRefine(
  ({ confirmPassword, newPassword }, ctx) =>
    confirmPassword !== newPassword &&
    ctx.addIssue({
      code: "custom",
      message: "ERROR_PASSWORDS_SHOULD_BE_EQUAL",
      path: ["confirmPassword"],
    }),
);

export const AuthFormSchema = zDiscriminatedUnion("intent", [
  EntryForm,
  ConfirmForm,
]);
