import { IResponse } from "models/common";
import {
  AuthDTO,
  type AuthRequest,
  type SetPasswordRequest,
} from "models/user.model";

import { authApi } from "./api";

export const authenticationApi = authApi
  //.enhanceEndpoints({ addTagTypes: ["Users", "Groups"] })
  .injectEndpoints({
    endpoints: (build) => ({
      login: build.query<AuthDTO, AuthRequest>({
        query: (payload) => ({
          url: "login",
          method: "POST",
          body: payload,
        }),
        transformResponse: (response: IResponse<AuthDTO>) => {
          const result = AuthDTO.safeParse(response.data);
          !result.success && console.log(result.error.format());
          return result.success ? result.data : response.data;
        },
      }),
      logout: build.query<void, string>({
        query: (token) => ({
          url: "logout",
          method: "POST",
          headers: { Authorization: token, "Content-Type": "application/json" },
        }),
      }),
      setPassword: build.query<void, SetPasswordRequest>({
        query: (payload) => ({
          url: "set-password",
          method: "POST",
          body: payload,
        }),
      }),
      refresh: build.mutation<void, string>({
        query: (token) => ({
          url: `/refresh`,
          method: "POST",
          headers: { Authorization: token, "Content-Type": "application/json" },
        }),
      }),
    }),
  });

export const { useLoginQuery } = authenticationApi;
