import { usePageId } from "contexts/LayoutContext";
import React from "react";
import { type LoaderFunction, redirect } from "react-router-dom";
import { getPermissionChecker } from "services/permissionChecker";
import { loadStore } from "storage";
import { Spinner } from "ui-kit";

const ScheduleLandingComponent = React.lazy(
  () => import("../../components/schedule-landing/schedule-landing"),
);

export const scheduleLandingLoader = (async () => {
  const store = await loadStore();
  const { hasPermissionFor } = await getPermissionChecker(store);

  if (!hasPermissionFor("SECTION_LANDING_SCHEDULE", true)) {
    return redirect("/hall-scheme");
  }
  return null;
}) satisfies LoaderFunction;
export function ScheduleLanding() {
  usePageId("schedule-landing");

  return (
    <React.Suspense fallback={<Spinner />}>
      <ScheduleLandingComponent />
    </React.Suspense>
  );
}
