import { duration } from "moment";
import { z } from "zod";

import { deserializeShiftData } from "../utils";

// Схема для проверки данных о смене
export const ShiftData = z.object({
  shiftId: z.number(),
  startHour: z.number().min(0).max(24),
  endHour: z.number().min(0).max(24),
  places: z.number().array(),
});

export type ShiftData = z.infer<typeof ShiftData>;

export const GuestAndTimeFormSchema = z.object({
  guests: z.coerce.number().int().min(2),
  shift: z.preprocess(deserializeShiftData, ShiftData),
  duration: z.number().int(),
});
export type GuestAndTimeFormSchema = z.infer<typeof GuestAndTimeFormSchema>;
