import { userApi } from "features/api/user-api";
import { type ActionFunction, redirect } from "react-router-dom";
import { Notification } from "services/notification";
import { getPermissionChecker } from "services/permissionChecker";
import { loadStore } from "storage";
import type { Params } from "types/commons";
import { ETranslations } from "types/translates";

export const deleteUserAction = (async ({ params }) => {
  const { userUUID } = params as Params;

  const store = await loadStore();

  const { hasPermissionFor } = await getPermissionChecker(store);

  if (!hasPermissionFor("FEATURE_MANAGEMENT_ROLE_ASSIGNING", true)) {
    return redirect("/settings/roles");
  }

  const deleteUser = await store.dispatch(
    userApi.endpoints.deleteUser.initiate(userUUID),
  );

  if ("error" in deleteUser || !deleteUser.data) {
    Notification.error(ETranslations.USER_DELETION_FAILED);
    return {
      formErrors: ETranslations.ERROR_SOMETHING_WENT_WRONG as const,
    };
  }

  Notification.success(ETranslations.USER_SUCCESSFULLY_DELETED);

  return redirect("/settings/roles");
}) satisfies ActionFunction;
