import cn from "classnames";
import { CancelBooking } from "components/ManagerialTables/form/CancelBooking";
import ModalPopup from "components/shifts/Modal/AccessRulesModal/ModalPopup";
import React from "react";
import { Form } from "react-final-form";
import { ETranslations } from "types/translates";
import { BottomBar, Button, Card } from "ui-kit";

import styles from "./Form.module.scss";
import RuleForm from "./RuleForm";
import useCurretRuleForm from "./useCurretRuleForm";

const CurrentRuleForm = ({
  onClose,
  currentRule,
}: {
  onClose: () => void;
  currentRule?: any;
}) => {
  const {
    initialValue,
    onSubmit,
    intl,
    openEditModal,
    toggleOpensSave,
    openDeleteModal,
    toggleOpenDeleteModal,
    error,
    onToggleCloseError,
    handleDeleteCurrentRule,
  } = useCurretRuleForm(currentRule, onClose);

  return (
    <Form initialValues={initialValue} onSubmit={onSubmit}>
      {({ handleSubmit, values }) => {
        return (
          <Card onClose={close}>
            <Card.Content noPadding>
              <form className={cn(styles.form)} onSubmit={handleSubmit}>
                <div className={styles.content}>
                  <RuleForm />
                </div>
                <BottomBar className={cn(styles.controls)}>
                  <BottomBar.Part placement="left">
                    <Button variant="secondary" onClick={toggleOpenDeleteModal}>
                      {intl.formatMessage({ id: ETranslations.BASE_DELETE })}
                    </Button>
                  </BottomBar.Part>
                  <BottomBar.Part placement="right">
                    <CancelBooking openModal={onClose} type="secondary" />
                    <Button variant="primary" onClick={toggleOpensSave}>
                      {intl.formatMessage({ id: ETranslations.SAVE })}
                    </Button>
                  </BottomBar.Part>
                </BottomBar>
              </form>
            </Card.Content>

            {!!error && (
              <ModalPopup
                buttons={
                  <Button variant="secondary" onClick={onToggleCloseError}>
                    {intl.formatMessage({ id: ETranslations.CANCEL })}
                  </Button>
                }
                isOpen={!!error}
                needIcon={true}
                text={error}
                title={intl.formatMessage({ id: ETranslations.BASE_ERROR })}
                onClose={onToggleCloseError}
              />
            )}

            {openDeleteModal && (
              <ModalPopup
                buttons={
                  <>
                    <Button
                      type="submit"
                      value={values?.index}
                      variant="dangerous"
                      onClick={handleDeleteCurrentRule}
                    >
                      {intl.formatMessage({ id: ETranslations.BASE_DELETE })}
                    </Button>
                    <Button variant="secondary" onClick={toggleOpenDeleteModal}>
                      {intl.formatMessage({ id: ETranslations.CANCEL })}
                    </Button>
                  </>
                }
                isOpen={openDeleteModal}
                text={intl.formatMessage({
                  id: ETranslations.DELETE_WARNING_TEXT,
                })}
                title={intl.formatMessage({ id: ETranslations.DELETE_AR })}
                onClose={toggleOpenDeleteModal}
              />
            )}

            {openEditModal && (
              <ModalPopup
                buttons={
                  <>
                    <Button
                      type="submit"
                      variant="primary"
                      onClick={handleSubmit}
                    >
                      {intl.formatMessage({ id: ETranslations.BASE_SAVE })}
                    </Button>
                    <Button variant="secondary" onClick={toggleOpensSave}>
                      {intl.formatMessage({ id: ETranslations.CANCEL })}
                    </Button>
                  </>
                }
                isOpen={openEditModal}
                needIcon={false}
                text={intl.formatMessage({
                  id: ETranslations.IMMEDIATELY_CHANGES,
                })}
                title={intl.formatMessage({
                  id: ETranslations.ASK_SAVE_CHANGES,
                })}
                onClose={toggleOpensSave}
              />
            )}
          </Card>
        );
      }}
    </Form>
  );
};

export default CurrentRuleForm;
