import { applicationContextActions } from "features/AppContext";
import { api } from "features/api";
import { authenticationApi } from "features/api/auth";
import { userApi } from "features/api/user-api";
import {
  type ActionFunction,
  redirect,
  redirectDocument,
} from "react-router-dom";
import { AuthService } from "services/auth.service";
import { loadStore } from "storage";
import type { ErrorResponse } from "types/commons";
import { ETranslations } from "types/translates";
import { commonFormErrorMap } from "utils";

import { AUTH_INTENT } from "../constants";
import { AuthFormSchema } from "../model/schema";

const handleErrorCode = (errorCode: number | undefined) => {
  if (errorCode === 11000) {
    return {
      shouldConfirmPassword: true,
      hasFormErrors: false,
      fieldErrors: undefined,
      formErrors: undefined,
    };
  }

  if (errorCode === 10000) {
    return {
      shouldConfirmPassword: false,
      hasFormErrors: true,
      fieldErrors: undefined,
      formErrors: ETranslations.AUTH_INCORRECT_CREDENTIALS,
    };
  }

  return {
    shouldConfirmPassword: false,
    hasFormErrors: true,
    fieldErrors: undefined,
    formErrors: ETranslations.ERROR_SOMETHING_WENT_WRONG,
  };
};

export const action = (async ({ request }) => {
  const formData = await request.formData();
  const formDataObj = Object.fromEntries(formData);
  const parsedData = AuthFormSchema.safeParse(formDataObj, {
    errorMap: commonFormErrorMap,
  });
  const store = await loadStore();

  // Если есть ошибки в форме, то не отправляем запрос на сервер, а сразу возвращаем ошибки
  if (!parsedData.success) {
    const validationErrors = parsedData.error.flatten();
    const intent = formDataObj.intent;
    return {
      ...validationErrors,
      hasFormErrors: Boolean(validationErrors.formErrors.length),
      shouldConfirmPassword: intent === AUTH_INTENT.CONFIRM,
    };
  }

  if (parsedData.data.intent === AUTH_INTENT.CONFIRM) {
    const setPasswordResponse = await store.dispatch(
      authenticationApi.endpoints.setPassword.initiate({
        locale: parsedData.data.locale,
        tenant: parsedData.data.tenant,
        login: parsedData.data.login,
        old_password: parsedData.data.password,
        new_password: parsedData.data.newPassword,
        confirm_new_password: parsedData.data.confirmPassword,
      }),
    );

    if (!setPasswordResponse.data) {
      return {
        shouldConfirmPassword: true,
        hasFormErrors: true,
        fieldErrors: undefined,
        formErrors: ETranslations.ERROR_SOMETHING_WENT_WRONG,
      };
    }
  }

  const loginPayload = {
    locale: parsedData.data.locale,
    tenant: parsedData.data.tenant,
    login: parsedData.data.login,
    password:
      parsedData.data.intent === AUTH_INTENT.CONFIRM
        ? parsedData.data.newPassword
        : parsedData.data.password,
  };

  // Отправляем запрос на бэк
  const authResp = await store.dispatch(
    authenticationApi.endpoints.login.initiate(loginPayload),
  );

  // Если запрос вернулся без даты, то есть с ошибкой
  if (!authResp.data) {
    // Возвращаем ошибку
    return handleErrorCode(
      (authResp.error as unknown as ErrorResponse["error"] | undefined)?.data
        ?.errorCode,
    );
  }

  // Ставим user'а в стор
  store.dispatch(applicationContextActions.setCurrentUser(authResp.data.user));

  // Ставим user'а в локалсторадж (а надо ли?)
  localStorage.setItem("user", JSON.stringify(authResp.data.user));

  // Для совместимости со старыми методами, убрать как будет время :(
  global?.channels?.auth?.cb && global.channels.auth.cb(true);

  // Добавляем токены в локалсторадж
  AuthService.setTokens(
    authResp.data.access_token,
    authResp.data.refresh_token,
  );

  // Начинаем префетчить разрешения для текущего пользователя
  store.dispatch(userApi.util.prefetch("getPermissions", undefined, {}));

  return redirect(parsedData.data.redirectTo);
}) satisfies ActionFunction;

export const logoutAction = (() => {
  const accessToken = AuthService.getTokens()?.access || "";

  loadStore().then((store) => {
    store.dispatch(authenticationApi.endpoints.logout.initiate(accessToken));
    store.dispatch(api.util.resetApiState());
    AuthService.logout();
  });

  return redirectDocument(`/login?redirectTo=/dashboard`);
}) satisfies ActionFunction;
