import { userApi } from "features/api/user-api";
import { type LoaderFunction, defer } from "react-router-dom";
import { loadStore } from "storage";
import type { Params } from "types/commons";

export const userInfoLoader = (({ params }) => {
  const { userUUID } = params as Params;
  const userData = loadStore().then((store) =>
    store.dispatch(userApi.endpoints.getUserById.initiate(userUUID)).unwrap(),
  );
  return defer({ userUUID, userData });
}) satisfies LoaderFunction;

