import { ETranslations } from "types/translates";
import { z } from "zod";

import { ShiftData } from "./model/schema";

export type ButtonParam = {
  text: string;
  value: string;
  defaultChecked?: boolean;
};

export function serializeShiftData(data: ShiftData): string {
  // Проверяем данные перед сериализацией
  ShiftData.parse(data);

  // Сериализуем в строку JSON
  return JSON.stringify(data);
}

export function deserializeShiftData(serializedData: unknown): ShiftData {
  try {
    // Парсим строку JSON
    const parsedData = JSON.parse(String(serializedData));

    // Проверяем структуру данных после десериализации
    return ShiftData.parse(parsedData);
  } catch (error) {
    // выбрасываем ошибку
    throw new Error(ETranslations.ERROR_SOMETHING_WENT_WRONG);
  }
}

export const getCountSuffix = (
  value: number,
  translates: { one: ETranslations; two: ETranslations; many: ETranslations },
) => {
  const allTranslates = {
    zero: translates.many,
    one: translates.one,
    two: translates.two,
    few: translates.many,
    many: translates.many,
    other: translates.many,
  };

  const lastTwoDigits = value % 100;
  const lastDigit = value % 10;

  if (
    (lastTwoDigits < 11 || lastTwoDigits > 19) &&
    lastDigit >= 2 &&
    lastDigit <= 4
  ) {
    return translates.two;
  }

  return allTranslates[new Intl.PluralRules("ru").select(value)];
};
