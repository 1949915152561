import cn from "classnames";
import { useEditStatusMutation } from "features/api/dictionaries-api";
import { ReactNode, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useCheckPermission } from "services/permissionChecker";
import type { StatusId } from "types/status";
import { ICONS } from "ui-kit";
import { ToggleSwitch } from "ui-kit/ToggleSwitch";

import { ETranslations } from "../../../../types/translates";
import type { FormattedStatus } from "./transform-data";

const CollapseItem = ({
  name,
  color,
  children,
}: {
  name: string;
  color: string;
  children?: ReactNode;
}) => {
  return (
    <article className="table-row blocks">
      <div className="details">
        <span className="status-name" style={{ backgroundColor: color }}>
          {name}
        </span>
      </div>
      {children}
    </article>
  );
};

export const StatusCollapse = ({
  categoryName,
  formattedStatuses,
  partialModalStatus,
  editStatus,
  isEditing,
}: {
  categoryName: string;
  formattedStatuses: FormattedStatus[] | undefined;
  partialModalStatus: (statusId: StatusId) => void;
  editStatus: ReturnType<typeof useEditStatusMutation>[0];
  isEditing: boolean;
}) => {
  const { formatMessage } = useIntl();
  const { hasPermissionFor } = useCheckPermission();
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className={`statuses sections include-table`}>
      <div
        className={`rest count-${formattedStatuses?.length} ${
          isOpen ? "active" : ""
        }`}
      >
        <div
          className="rest-info blocks"
          onClick={() => setIsOpen((prev) => !prev)}
        >
          <span>{categoryName}</span>
          <figure className={cn("arrow", { active: isOpen })}>
            <ICONS.ArrowDown />
          </figure>
        </div>
        <div
          className="table rows custom-scroll"
          // onAnimationStart={(e: any) => e.target.classList.remove('opened')}
          // onAnimationEnd={(e: any) => e.target.classList.add('opened')}
        >
          {formattedStatuses?.length ? (
            formattedStatuses.map(
              ({ id, color, name, is_active, is_extra }) => (
                <CollapseItem color={color} key={id} name={name}>
                  {is_extra &&
                    hasPermissionFor("ACCESS_MANAGEMENT_STATUSES_UPDATE") && (
                      <div className="controls">
                        <button
                          className="edit"
                          onClick={(e) => {
                            e.stopPropagation();
                            partialModalStatus(id);
                          }}
                        />
                        <ToggleSwitch
                          disabled={isEditing}
                          isEnabled={is_active}
                          onToggle={() =>
                            editStatus({
                              status_id: id,
                              is_active: !is_active,
                            }).then((data) =>
                              "data" in data
                                ? data.data.data.is_active
                                : undefined,
                            )
                          }
                        />
                      </div>
                    )}
                </CollapseItem>
              ),
            )
          ) : (
            <div className="message">
              {formatMessage({ id: ETranslations.NOT_FOUND })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
