import cn from "classnames";
import { DefaultErrorBoundary } from "components/ErrorBoundary";
import { Modal } from "components/modal";
import { Suspense } from "react";
import { useIntl } from "react-intl";
import {
  Await,
  Form,
  useActionData,
  useLoaderData,
  useNavigate,
} from "react-router-dom";
import { useCheckPermission } from "services/permissionChecker";
import { ETranslations } from "types/translates";
import { Button, FieldError, ICONS } from "ui-kit";

import type { deleteUserAction } from "../../api/deleteUserAction";
import type { userInfoLoader } from "../../api/userInfoLoader";
import styles from "../UserForm.module.scss";

const ShortUserInfo = () => {
  const { userData } = useLoaderData<typeof userInfoLoader>();
  const { formatMessage } = useIntl();
  return (
    <Suspense
      fallback={
        <span
          className={styles.shortUserInfo}
        >{`${formatMessage({ id: ETranslations.FULL_NAME })}: ${formatMessage({ id: ETranslations.LOADING })} 
          ${formatMessage({ id: ETranslations.AUTH_LOGIN })}: ${formatMessage({ id: ETranslations.LOADING })} 
          ${formatMessage({ id: ETranslations.ROLE })}: ${formatMessage({ id: ETranslations.LOADING })} `}</span>
      }
    >
      <Await
        resolve={userData}
        errorElement={
          <DefaultErrorBoundary entity={ETranslations.OF_USER}>
            {ETranslations.ERROR_ENTITY}
          </DefaultErrorBoundary>
        }
      >
        {(data) => (
          <span
            className={styles.shortUserInfo}
          >{`${formatMessage({ id: ETranslations.FULL_NAME })}: ${data.user_profile.fio} 
          ${formatMessage({ id: ETranslations.AUTH_LOGIN })}: ${data.username} 
          ${formatMessage({ id: ETranslations.ROLE })}: ${data.user_group.group_translation_name || data.user_group.group_name}`}</span>
        )}
      </Await>
    </Suspense>
  );
};

const DeleteUserModal = () => {
  const actionData = useActionData<typeof deleteUserAction>();
  const { formatMessage } = useIntl();
  const { hasPermissionFor } = useCheckPermission();
  const navigate = useNavigate();
  const close = () => navigate("/settings/roles");
  return (
    <Modal className={styles.conformationModal} isOpen onClose={close}>
      <Modal.Content className={styles.body}>
        <ICONS.WARNING className={cn(styles.icon, styles.danger)} />
        <h2>
          {formatMessage(
            { id: ETranslations.BASE_ENTITY_DELETING },
            {
              entity: formatMessage({
                id: ETranslations.OF_USER,
              }),
            },
          )}
        </h2>
        <p className={styles.conformationMessage}>
          {formatMessage({ id: ETranslations.DELETE_USER_MESSAGE })}
          <ShortUserInfo />
          <span className={styles.irreversibleAction}>
            {formatMessage({ id: ETranslations.IRREVERSIBLE_ACTION })}
          </span>
          {actionData?.formErrors && (
            <FieldError error={actionData.formErrors} />
          )}
        </p>
        <div className={styles.actions}>
          <Form method="DELETE">
            <Button
              type="submit"
              variant="dangerous"
              disabled={!hasPermissionFor("FEATURE_MANAGEMENT_ROLE_ASSIGNING")}
            >
              {formatMessage({ id: ETranslations.BASE_CONFIRM })}
            </Button>
          </Form>
          <Button type="button" variant="secondary" onClick={close}>
            {formatMessage({ id: ETranslations.BASE_CANCEL })}
          </Button>
        </div>
      </Modal.Content>
    </Modal>
  );
};

DeleteUserModal.displayName = "DeleteUserModal";
export { DeleteUserModal };
