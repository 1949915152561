import EachTagGroup from "components/EachTagGroup/EachTagGroup";
import { CreateGroupTagsModal } from "components/EachTagGroup/Modals/CreateGroupTagsModal";
import { restaurantSelector } from "features/AppContext";
import { useGetNewTagsQuery } from "features/api/tags-api";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useCheckPermission } from "services/permissionChecker";
import type { TagGroup } from "types/tag";
import { ETranslations } from "types/translates";
import { Button, RadioButton, Spinner } from "ui-kit";

import "../settings.scss";

export default function TagsPage() {
  const [selectedTag, setSelectedTag] =
    useState<TagGroup["destination"]>("BOOKING");
  const [isOpenCreateModal, setIsOpenCreateModal] = useState<boolean>(false);
  const restaurant = useSelector(restaurantSelector);
  const { hasPermissionFor } = useCheckPermission();
  const { data, isLoading } = useGetNewTagsQuery({
    destination: selectedTag,
    restaurant_id: `${restaurant.restaurant_id}`,
  });
  const { formatMessage } = useIntl();
  const onToggleOpenCreateModal = () =>
    setIsOpenCreateModal((prevState) => !prevState);

  return isLoading ? (
    <Spinner />
  ) : (
    <div className="access_rules sections include-table">
      <div className="tags_button">
        <RadioButton value={selectedTag} onChange={setSelectedTag}>
          <RadioButton.Button value="BOOKING">
            {formatMessage({ id: ETranslations.PLURAL_BOOKING })}
          </RadioButton.Button>
          <RadioButton.Button value="CLIENT">
            {formatMessage({ id: ETranslations.PLURAL_GUEST })}
          </RadioButton.Button>
        </RadioButton>
        <div>
          {hasPermissionFor("ACCESS_MANAGEMENT_TAG_CREATE") && (
            <Button variant="primary" onClick={onToggleOpenCreateModal}>
              {formatMessage({ id: ETranslations.CREATE_GROUP })}
            </Button>
          )}
        </div>
      </div>
      <div className="access_rules_box">
        {data?.map((eachGroup: TagGroup) => (
          <EachTagGroup
            eachGroup={eachGroup}
            key={eachGroup.id}
            selectedTag={selectedTag}
          />
        ))}
      </div>
      {isOpenCreateModal && (
        <CreateGroupTagsModal
          isOpen={isOpenCreateModal}
          selectedTag={selectedTag}
          title={formatMessage({ id: ETranslations.GROUP_CREATING })}
          onClose={onToggleOpenCreateModal}
        />
      )}
    </div>
  );
}
