import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useDialog } from "features/Dialogs/SDK";
import { useFetchFeedsQuery } from "features/api/communication";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { useCheckPermission } from "services/permissionChecker";
import type { ErrorResponse, Params } from "types/commons";

export const useFeedList = () => {
  const { dialogId } = useParams<Params>();
  const { formatMessage } = useIntl();

  const { dialog, loading, error } = useDialog(dialogId || "");
  const {
    data,
    isError,
    isLoading,
    error: fetchError,
  } = useFetchFeedsQuery(
    dialog
      ? {
          body: {
            client_id: Number(dialog.customer.id),
            restaurant_ids: [dialog.partner!.id],
          },
        }
      : skipToken,
  );
  const { hasPermissionFor } = useCheckPermission();
  const showReservationFromDialogButton =
    dialogId &&
    dialog?.customer.id &&
    dialog?.partner?.id &&
    hasPermissionFor("FEATURE_DIALOGS_BOOKING_CREATE");
  return {
    dialogId,
    clientId: dialog?.customer.id,
    restaurantId: dialog?.partner?.id,
    feeds: data?.content,
    isDialogLoaded: Boolean(dialog),
    isLoading: loading || isLoading,
    isErrorOrEmpty: Boolean(error || !data?.content.length || isError),
    errorMessage: JSON.stringify(
      error || (fetchError as ErrorResponse["error"]) || "",
    ),
    showReservationFromDialogButton,
    formatMessage,
  };
};
