import cn from "classnames";
import {
  type ElementType,
  type ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import { useIntl } from "react-intl";
import { ETranslations } from "types/translates";
import { ICONS } from "ui-kit/ICONS";

import styles from "./ExpandableContent.module.scss";

export const ExpandableContent = ({
  children,
  className,
  id,
  lineCount,
  as,
}: {
  children: ReactNode;
  className?: string;
  id: number | string;
  lineCount?: number;
  as?: ElementType;
}) => {
  const { formatMessage } = useIntl();
  const [showToggleButton, setShowToggleButton] = useState(false);
  const contentRef = useRef<HTMLElement>(null);
  const checkboxRef = useRef<HTMLInputElement>(null);
  const Content = as || "p";

  useEffect(() => {
    if (contentRef.current) {
      const observer = new ResizeObserver((entries) => {
        const isReviewClamped =
          entries[0].target.scrollHeight > entries[0].contentRect.height;
        const isExpanded = checkboxRef.current?.checked;

        // Показать кнопку, если контент обрезан, иначе скрыть кнопку, если не раскрыто
        // todo: подправить код, чтобы кнопка Less тоже исчезала, если она нее нужна
        isReviewClamped
          ? setShowToggleButton(true)
          : !isExpanded && setShowToggleButton(false);
      });

      observer.observe(contentRef.current);
      return () => observer.disconnect();
    }
  }, [contentRef.current]);
  return (
    <>
      <input
        className={styles.hiddenCheckbox}
        hidden
        ref={checkboxRef}
        id={`expanded-${id}`}
        type="checkbox"
      />
      <Content
        className={cn(styles.expandableContent, className)}
        style={lineCount ? { WebkitLineClamp: lineCount } : undefined}
        ref={contentRef}
      >
        {children}
      </Content>
      {showToggleButton && (
        <button type="button" className={styles.showMoreButton}>
          <label
            data-showless={formatMessage({ id: ETranslations.LESS })}
            data-showmore={formatMessage({ id: ETranslations.MORE })}
            htmlFor={`expanded-${id}`}
          >
            <ICONS.ArrowDown />
          </label>
        </button>
      )}
    </>
  );
};
