import { useIntl } from "react-intl";
import { Form, useSearchParams } from "react-router-dom";
import { useCheckPermission } from "services/permissionChecker";
import { ETranslations } from "types/translates";
import { ICONS, Input, LinkButton } from "ui-kit";

import styles from "./RolesHeader.module.scss";

export const RolesHeader = () => {
  const { formatMessage } = useIntl();
  const { hasPermissionFor } = useCheckPermission();
  const [searchParams, setSearchParams] = useSearchParams();
  const searchValue = searchParams.get("q");
  const changeSearchParams = (q: string, isFirstSearch: boolean) =>
    setSearchParams((prev) => (prev.set("q", q), prev), {
      replace: !isFirstSearch,
    });

  return (
    <header className={styles.header}>
      <Form role="search" className={styles.searchForm}>
        <Input
          prefix={<ICONS.Search />}
          placeholder={formatMessage(
            { id: ETranslations.FULL_NAME_OF },
            { entity: formatMessage({ id: ETranslations.OF_USER }) },
          )}
          type="search"
          role="searchbox"
          defaultValue={searchValue || ""}
          onChange={(e) =>
            changeSearchParams(e.currentTarget.value, searchValue === null)
          }
        />
      </Form>
      <LinkButton
        variant="primary"
        to="create"
        disabled={!hasPermissionFor("FEATURE_MANAGEMENT_ROLE_ASSIGNING")}
      >
        {formatMessage(
          { id: ETranslations.BASE_ADD_ENTITY },
          {
            entity: formatMessage({ id: ETranslations.OF_USER }).toLowerCase(),
          },
        )}
      </LinkButton>
    </header>
  );
};
