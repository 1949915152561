import { useIntl } from "react-intl";
import { ETranslations } from "types/translates";
import { Spinner } from "ui-kit";
import { RadioGroup } from "ui-kit/RadioButton/RadioButton";

const LoadingShiftRadioGroup = () => {
  const { formatMessage } = useIntl();
  return (
    <RadioGroup.Root
      groupName="shift"
      label={formatMessage({ id: ETranslations.AVAILABLE_TIME })}
      required
    >
      <Spinner />
    </RadioGroup.Root>
  );
};
LoadingShiftRadioGroup.displayName = "LoadingShiftRadioGroup";

const ErrorShiftRadioGroup = ({ error }: { error: string }) => {
  const { formatMessage } = useIntl();
  return (
    <RadioGroup.Root
      groupName="shift"
      label={formatMessage({ id: ETranslations.AVAILABLE_TIME })}
      required
    >
      {formatMessage(
        { id: ETranslations.ERROR_ENTITY },
        {
          entity: formatMessage({
            id: ETranslations.SETTINGS_TITLE_SHIFTS,
          }).toLowerCase(),
          error,
        },
      )}
    </RadioGroup.Root>
  );
};
ErrorShiftRadioGroup.displayName = "ErrorShiftRadioGroup";

export { LoadingShiftRadioGroup, ErrorShiftRadioGroup };
