import { PERMISSION_NAME_LIST } from "constants/permissions";
import { Role } from "services/auth.service";
import type { ELocales } from "types/commons";
import { z } from "zod";

import { RestaurantId } from "./booking.model";

export class AppointUser {
  constructor(
    public name: string,
    public login: string,
    public restaurant_id: number,
    public role: Role,
    public photo: string | null,
    public phone: string | null,
    public id?: number,
    public max_guests?: number,
    public password?: string,
  ) {}
}

export const UserId = z.number().brand("UserId");
export type UserId = z.infer<typeof UserId>;

export const UserUUID = z.string().uuid().brand("UserUUID");
export type UserUUID = z.infer<typeof UserUUID>;

export const User = z.object({
  serial_id: UserId,
  user_id: z.number(),
  user_name: z.string(),
  user_login: z.string(),
});

export type User = z.infer<typeof User>;

const UserGroupType = z.enum(["NOT_PERSONAL", "PERSONAL"]);

export const UserGroupId = z.number().int().brand("UserGroupId");
export type UserGroupId = z.infer<typeof UserGroupId>;

export const UserGroup = z.object({
  id: UserGroupId,
  group_type: UserGroupType,
  group_name: z.string(),
  group_translation_name: z.string(),
  group_weight: z.number(),
});
export type UserGroup = z.infer<typeof UserGroup>;

export const GrantedUsersDTO = z
  .object({
    user_serial: UserUUID,
    username: z.string(),
    user_profile: z.object({
      id: UserId,
      fio: z.string(),
      photo: z.string().nullable(),
      phone: z.string().nullable(),
      email: z.string().nullable(),
      extension_phone: z.string().nullable(),
    }),
    user_group: UserGroup,
    active_in_restaurants: RestaurantId.array(),
  })
  .array();
export type GrantedUser = z.infer<typeof GrantedUsersDTO>[number];

type UserProfile = {
  email?: string;
  photo?: string;
  full_name?: string;
  phone_number?: string;
  phone_number_ext?: string;
};

export type UserCreateRequest = {
  login: string;
  profile?: UserProfile;
  restaurants_membership: RestaurantId[];
  access_group_id: UserGroupId;
};

export type UserModifyRequest = UserProfile & {
  userUUID: UserUUID;
  restaurants_membership: RestaurantId[];
  access_group_id: UserGroupId;
};

export const PermissionName = z.enum(PERMISSION_NAME_LIST);
export type PermissionName = z.infer<typeof PermissionName>;

export const PermissionDTO = z.object({
  permission_type: z.enum(["ACCESS", "SECTION", "FEATURE"]),
  permission_system_name: PermissionName,
  permission_translate_name: z.string(),
});
export type PermissionDTO = z.infer<typeof PermissionDTO>;

export type PermissionByName = Partial<Record<PermissionName, PermissionDTO>>;

export type AuthRequest = {
  locale: ELocales;
  tenant: string;
  login: string;
  password: string;
};

export const AuthDTO = z.object({
  user: User,
  access_token: z.string(),
  refresh_token: z.string(),
  id_token: z.string(),
  token_type: z.string(),
  expires_in: z.number(),
  refresh_expires_in: z.number(),
  session_state: z.string(),
  scope: z.string(),
  "not-before-policy": z.string(),
});
export type AuthDTO = z.infer<typeof AuthDTO>;

export type SetPasswordRequest = {
  locale: ELocales;
  tenant: string;
  login: string;
  old_password: string;
  new_password: string;
  confirm_new_password: string;
};
