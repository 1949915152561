import { zodResolver } from "@hookform/resolvers/zod";
import { type ReactNode, useState } from "react";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import type { WeekShift } from "types/shift";
import { ETranslations } from "types/translates";
import { RadioGroup } from "ui-kit/RadioButton/RadioButton";
import { commonFormErrorMap } from "utils";

import { useCreateBookingFormContext } from "../../context/CreateBookingContext";
import { CreateBookingFormFooter } from "../CreateBookingFormLayout";
import { StepContainer } from "../FormComponents";
import { BookingDurationSelect } from "./BookingDurationSelect";
import styles from "./GuestAndTimeStep.module.scss";
import { GuestCountSelect } from "./GuestCountCustomSelect";
import {
  ErrorShiftRadioGroup,
  LoadingShiftRadioGroup,
} from "./LoadingShiftRadioGroup";
import {
  MultiShiftRadioButtons,
  getMultiShiftButtonsParam,
} from "./MultiShiftRadioButtons";
import { ShiftRadioGroup } from "./ShiftRadioGroup";
import {
  SingleShiftRadioButtons,
  getSingleShiftButtonsParams,
} from "./SingleShiftRadioButtons";
import { GuestAndTimeFormSchema } from "./model/schema";
import { type ButtonParam } from "./utils";

const renderShiftButtons = (
  shifts: WeekShift[],
  defaultShiftId: number,
  render: (buttonParam: ButtonParam) => ReactNode,
) =>
  shifts.length > 1 ? (
    <MultiShiftRadioButtons>
      {shifts.map((shift) =>
        render(
          getMultiShiftButtonsParam({
            shiftId: shift.id,
            startDate: shift.start_date,
            endDate: shift.end_date,
            places: shift.places,
            shiftName: shift.title,
            defaultShiftId,
          }),
        ),
      )}
    </MultiShiftRadioButtons>
  ) : (
    <SingleShiftRadioButtons>
      {getSingleShiftButtonsParams({
        shiftId: shifts[0].id,
        startDate: shifts[0].start_date,
        endDate: shifts[0].end_date,
        places: shifts[0].places,
      }).map(render)}
    </SingleShiftRadioButtons>
  );

export const GuestAndTimeStep = () => {
  const { formatMessage } = useIntl();
  const { formValues, updateValues, updateValidationState } =
    useCreateBookingFormContext();
  const [isCustomGuestCount, setIsCustomGuestCount] = useState(false);
  const { register, handleSubmit, formState, control } =
    useForm<GuestAndTimeFormSchema>({
      resolver: zodResolver(GuestAndTimeFormSchema, {
        errorMap: commonFormErrorMap,
      }),
      defaultValues: {
        guests: 2,
      },
      mode: "onBlur",
    });

  return (
    <>
      <StepContainer>
        <form
          className={styles.guestAndTimeForm}
          onSubmit={handleSubmit(console.log)}
        >
          <RadioGroup.Root
            groupName="guests"
            defaultValue="2"
            label={formatMessage({ id: ETranslations.NUMBER_OF_GUESTS_TITLE })}
            required
            variant="flat"
            onChange={(value) => setIsCustomGuestCount(Number(value) >= 7)}
            error={formState.errors.guests?.message}
          >
            <RadioGroup.Content>
              <RadioGroup.Button {...register("guests")} value={2} />
              <RadioGroup.Button {...register("guests")} value={3} />
              <RadioGroup.Button {...register("guests")} value={4} />
              <RadioGroup.Button {...register("guests")} value={5} />
              <RadioGroup.Button {...register("guests")} value={6} />
              <RadioGroup.Button {...register("guests")} value={7}>
                {formatMessage({ id: ETranslations.CUSTOM })}
              </RadioGroup.Button>
            </RadioGroup.Content>
            {isCustomGuestCount && (
              <GuestCountSelect name="guests" control={control} />
            )}
          </RadioGroup.Root>
          <ShiftRadioGroup
            restaurantId={formValues.restaurantId}
            date={formValues.bookingDate}
            LoadingComponent={LoadingShiftRadioGroup}
            ErrorComponent={ErrorShiftRadioGroup}
          >
            {(shifts, defaultShiftId) =>
              renderShiftButtons(shifts, defaultShiftId, (buttonParam) => (
                <RadioGroup.Button
                  {...register("shift")}
                  key={buttonParam.value}
                  defaultChecked={buttonParam.defaultChecked}
                  value={buttonParam.value}
                >
                  {buttonParam.text}
                </RadioGroup.Button>
              ))
            }
          </ShiftRadioGroup>
          <BookingDurationSelect
            name="duration"
            label={formatMessage({ id: ETranslations.RESERVATION_DURATION })}
            control={control}
            timeInterval={15}
          />
          <button>submit</button>
        </form>
      </StepContainer>
      {formValues.visitors && <CreateBookingFormFooter currentStep={4} />}
    </>
  );
};
