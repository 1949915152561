import { userApi } from "features/api/user-api";
import { type ActionFunction, redirect } from "react-router-dom";
import { Notification } from "services/notification";
import { getPermissionChecker } from "services/permissionChecker";
import { loadStore } from "storage";
import type { Params } from "types/commons";
import { ETranslations } from "types/translates";
import { commonFormErrorMap, formDataToObject } from "utils";

import { UserRoleFormSchema } from "../model/schema";

export const editUserAction = (async ({ params, request }) => {
  const { userUUID } = params as Params;
  const formDataObj = await request.formData().then(formDataToObject);
  const parsedData = UserRoleFormSchema.safeParse(formDataObj, {
    errorMap: commonFormErrorMap,
  });

  if (!parsedData.success) return parsedData.error.flatten();

  const store = await loadStore();

  const { hasPermissionFor } = await getPermissionChecker(store);

  if (!hasPermissionFor("FEATURE_MANAGEMENT_ROLE_ASSIGNING", true)) {
    return redirect("/settings/roles");
  }

  const modifyUser = await store.dispatch(
    userApi.endpoints.modifyUser.initiate({
      full_name: parsedData.data.fullName || undefined,
      phone_number: parsedData.data.phone || undefined,
      email: parsedData.data.email || undefined,
      phone_number_ext: parsedData.data.extensionPhone || undefined,
      userUUID,
      restaurants_membership: parsedData.data.restaurant,
      access_group_id: parsedData.data.role,
    }),
  );

  if ("error" in modifyUser || !modifyUser.data) {
    Notification.error(ETranslations.USER_MODIFICATION_FAILED);
    return {
      formErrors: ETranslations.ERROR_SOMETHING_WENT_WRONG as const,
      fieldErrors: undefined,
    };
  }

  Notification.success(ETranslations.USER_SUCCESSFULLY_MODIFIED);

  return redirect("/settings/roles");
}) satisfies ActionFunction;
