import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { config } from "config";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import type { BookingHistoryDTO } from "models/history.model";
import type { Source } from "models/source.model";
import type { Tag } from "models/tags.model";
import type { User } from "models/user.model";
import type { ELocales } from "types/commons";

import { useDispatchActions } from "../../hooks/useDispatchActions";
import { type BookingSlot, type ManagerSlot } from "../../models/booking.model";
import { Place, Restaurant } from "../../models/restaurant.model";
import type { Booking, BookingOrder } from "../../types/booking";
import { tryCatch } from "../../utils/common";
import { validateLocale } from "../../utils/locale";

dayjs.extend(utc);
dayjs.extend(timezone);

export interface State {
  currentUser: User;
  locale: ELocales;
  restaurants: Restaurant[];
  tags: Tag[];
  sources: Source[];
  date: string;
  place: number | null;
  restaurant: Restaurant;
  // TODO тут по идее нужно подумать как хранить места только в одном атрибуте
  selectedPlaces: number[];
}

export const initialState: State = {
  currentUser: (tryCatch(
    () => JSON.parse(localStorage.getItem("user") || ""),
    () => null,
  ) || {}) as User,
  locale: validateLocale(
    tryCatch(() => JSON.parse(localStorage.getItem("locale") || "")),
  ),
  restaurants: [],
  tags: [],
  sources: [],
  date: dayjs.tz().toISOString(),
  place: null,
  restaurant: null!,
  selectedPlaces: [],
};

const slice = createSlice({
  name: "applicationContext",
  initialState,
  reducers: {
    setUpRestaurants(state, { payload }: PayloadAction<Restaurant[]>) {
      state.restaurants = payload;
    },
    setUpTags(state, action: PayloadAction<Pick<State, "tags">>) {
      state.tags = action.payload.tags;
    },
    setUpSources(state, action: PayloadAction<Pick<State, "sources">>) {
      state.sources = action.payload.sources;
    },
    setDate(state, { payload }: PayloadAction<string>) {
      state.date = payload;
    },
    setPlace(state, { payload }: PayloadAction<number | null>) {
      state.place = payload;
    },
    setPlaceFromBooking(
      state,
      {
        payload,
      }: PayloadAction<
        BookingSlot | Booking | ManagerSlot | BookingHistoryDTO["booking"]
      >,
    ) {
      const firstPlace =
        "slot_place" in payload
          ? payload.slot_place[0]
          : "slot_places" in payload
            ? payload.slot_places[0]
            : payload.places[0];
      state.place = firstPlace?.hall_id;
    },
    setPlaceFromOrder(state, { payload }: PayloadAction<BookingOrder>) {
      const placeId = payload?.places?.[0].placeId;
      state.place = placeId;
    },
    setSelectedPlaces(state, { payload }: PayloadAction<number[]>) {
      state.selectedPlaces = payload;
    },
    setSelectedPlace(state, { payload }: PayloadAction<Place>) {
      state.selectedPlaces = [payload.id];
    },
    setRestaurant(state, { payload }: PayloadAction<Restaurant>) {
      const places = payload.places;
      state.restaurant = { ...payload, places };
      dayjs.tz.setDefault(
        // FIXME: костыль. убрать, когда бэк станет возвращать для дубая верную таймзону
        config.BRAND === "DUBAI" ? "Asia/Dubai" : payload.timezone,
      );
      state.selectedPlaces = payload.places.map((p) => p.id);
      [state.place] = state.selectedPlaces;
    },
    setCurrentUser(state, { payload }: PayloadAction<User>) {
      state.currentUser = payload;
    },
    setLocale(state, { payload }: PayloadAction<ELocales>) {
      state.locale = payload;
    },
  },
});

export const {
  name: applicationContextSliceName,
  reducer: applicationContextReducer,
  actions: applicationContextActions,
} = slice;
export const useApplicationContextActions = () =>
  useDispatchActions(slice.actions);
