/* eslint-disable no-param-reassign */
// For mock backend
// import './test_helpers';
import _ from "lodash";
import {
  Booking,
  BookingInfoFilter,
  BookingResponse,
  HistoryBooking,
} from "models/booking.model";
import type { Client, Contact } from "models/client.model";
import { ChangesParams, SingleOption, Table } from "models/common";
import { SchemeDrillDown } from "models/hall-scheme.model";
import type { BookingHistoryDTO } from "models/history.model";
import { AppointUser } from "models/user.model";
import _M from "moment";
import "moment/locale/ru";
import React, { SyntheticEvent } from "react";
import {
  type Value as E164Number,
  formatPhoneNumberIntl,
} from "react-phone-number-input";
import { BookingOrder, ManagerialClient } from "types/booking";
import type { Status } from "types/status";
import { ICONS } from "ui-kit";

import { ETranslations } from "../types/translates";
import PreData from "./PreData";
import SnapTime from "./time";

export { ICONS } from "ui-kit";

// eslint-disable-next-line
_M.fn.toJSON = function () {
  return this.format("YYYY-MM-DD[T]HH:mm:ss");
};
export const M = _M;

export function timeValue(options: SingleOption[], bookingTime?: string) {
  if (!bookingTime) return [];
  return (
    (options as SingleOption[]).find((it) => it.label === bookingTime) ||
    new SingleOption(0, bookingTime || "")
  );
}

export function pad2(val: number | string): string {
  return _.padStart(val.toString(), 2, "0");
}

export function generateMaskImage(addr: string): React.CSSProperties {
  return { WebkitMaskImage: `url(${addr})`, maskImage: `url(${addr})` };
}

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      "progress-ring": any;
    }
  }
}

export const undefinedString = ETranslations.BASE_NOT_INDICATED;

export const colorStyles = {
  multiValue: (styles: any, { data }: any) => ({
    ...styles,
    borderRadius: "20px",
    padding: "0 15px",
    height: "20px",
    color: "var(--gl_text_content_primary)",
    alignItems: "center",
    marginBottom: "3px",
    backgroundColor: data.color,
  }),
  multiValueLabel: () => ({
    padding: "0",
    color: "var(--gl_text_content_primary)",
  }),
  multiValueRemove: (styles: any) => ({
    ...styles,
    ":hover": { backgroundColor: "none", color: "red", cursor: "pointer" },
  }),
};

/**
 * Склонение русских существительных в соответствии с числительными
 * @param iNumber
 * Число, с которым надо склонить
 *
 * @param aEndings
 * Массив существительных по схеме 1,4,5. Например [яблоко, яблока, яблок]
 */
export function rusDeclension(iNumber: number, aEndings: string[]) {
  let sEnding, i;
  // if (iNumber == 0) return 'нет ' + aEndings[2]
  iNumber = Math.abs(iNumber) % 100;
  if (iNumber >= 11 && iNumber <= 19) {
    // eslint-disable-next-line prefer-destructuring
    sEnding = aEndings[2];
  } else {
    i = iNumber % 10;
    switch (i) {
      case 1:
        // eslint-disable-next-line prefer-destructuring
        sEnding = aEndings[0];
        break;
      case 2:
      case 3:
      case 4:
        // eslint-disable-next-line prefer-destructuring
        sEnding = aEndings[1];
        break;
      default:
        // eslint-disable-next-line prefer-destructuring
        sEnding = aEndings[2];
    }
  }
  return sEnding;
}

const hourAEndings = ["час", "часа", "часов"];
const MIN_STEP = 15;
export const HOURS: SingleOption[] = _.range(24).map(
  (value) =>
    new SingleOption(value, `${value} ${rusDeclension(value, hourAEndings)}`),
);
export const MINUTES: SingleOption[] = _.range(0, 60, MIN_STEP).map(
  (value) => new SingleOption(value, `${pad2(value)} мин`),
);
export const HOURS_MINS: SingleOption[] = _.flatMap(_.range(24), (h, i) =>
  _.range(0, 60, MIN_STEP).map(
    (m, j) =>
      new SingleOption((i * 60) / MIN_STEP + j, `${pad2(h)}:${pad2(m)}`),
  ),
);

export class Channel<T> {
  // eslint-disable-next-line
  constructor(public cb?: (e?: T) => void) {}

  sendValueToCB(value?: T) {
    if (_.isFunction(this.cb)) {
      this.cb(value);
    } else {
      console.warn("Value was sent but no callback was registered");
    }
  }
}

export class Broadcast<T> {
  // eslint-disable-next-line
  constructor(public cb: ((e?: T) => void)[] = []) {}

  sendValueToCB(value?: T) {
    this.cb.forEach((f) => {
      if (_.isFunction(f)) {
        f(value);
      } else {
        console.warn(
          "<Broadcast>Value was sent but no callback was registered",
        );
      }
    });
  }
}

export function cardsOrder(
  a: Booking | HistoryBooking,
  b: Booking | HistoryBooking,
): number {
  return +new Date(a.date) - +new Date(b.date);
}

export function minsToString(m: number): string {
  const value = Math.abs(m);
  return `${m < 0 ? "-" : ""}${Math.floor(value / 60)}:${pad2(value % 60)}`;
}

export const formatMinutesDuration = (m: number) => {
  const days = Math.trunc(m / 1440);
  const hours = Math.trunc(m / 60 - days * 24);
  const minutes = Math.abs(m % 60);
  return ((days ? `${days}:${Math.abs(hours)}:` : `${hours}:`) +
    `${minutes}`.padStart(2, "0")) as `${number}:${number}`;
};

export function minsToStringManagerialTable(m: number): string {
  const value = Math.abs(m);
  return `${m > 0 ? "-" : ""}${Math.floor(value / 60)}:${pad2(value % 60)}`;
}

export function setArbitraryField(
  field: string,
  value: any,
  callback?: Function,
) {
  // @ts-ignore
  this.setState(
    (state: any) => _.set({ ...state }, field, value),
    () => {
      if (_.isFunction(callback)) {
        callback();
      }
    },
  );
}

export function sleep(ms: number): Promise<any> {
  return new Promise((resolve) => setTimeout(() => resolve(undefined), ms));
}

export function getMaskPhone(phone: string): string {
  if (phone.startsWith("+")) {
    phone = phone.slice(1);
  }
  if (!phone.startsWith("7")) {
    try {
      return (
        formatPhoneNumberIntl(
          phone.replace(/^([^\D])/g, "+$1") as E164Number,
        ) ?? phone
      );
    } catch {
      return phone;
    }
  }
  // prefix code start middle end postsript
  //   +7    900   259   56    54  455...
  // eslint-disable-next-line
  const prefix = phone.slice(0, 1) === "" ? "" : `+${phone.slice(0, 1)}`;
  let code = "";
  if (phone.slice(1, 4) === "") {
    code = "";
  } else if (phone.slice(1, 4).length < 3) {
    code = ` (${phone.slice(1, 4)}`;
  } else {
    code = ` (${phone.slice(1, 4)}) `;
  }
  const start = phone.slice(4, 7) === "" ? "" : `${phone.slice(4, 7)}`;
  const middle = phone.slice(7, 9) === "" ? "" : `-${phone.slice(7, 9)}`;
  const end = phone.slice(9, 11) === "" ? "" : `-${phone.slice(9, 11)}`;
  // eslint-disable-next-line
  const postscript = phone.slice(11) === "" ? "" : `${phone.slice(11)}`;
  return prefix + code + start + middle + end + postscript;
}

export function hmin(mins: number): number {
  return Math.floor(mins / 60);
}

export function roundFloor(val: number, accuracy: number): number {
  return Math.floor(val / accuracy) * accuracy;
}

export function roundCeil(val: number, accuracy: number): number {
  return Math.ceil(val / accuracy) * accuracy;
}

declare global {
  // eslint-disable-next-line
  namespace NodeJS {
    interface Global {
      channels: {
        auth: Channel<boolean>;
        requestConfirm: Channel<Booking>;
        requestSent: Channel<string>;
        createBooking: Channel<void>;
        guestListToCreate: Channel<Client>;
      };
      fetch: any;
      env?: "test";
    }
  }
}

global.channels = {
  auth: new Channel(),
  requestConfirm: new Channel(),
  requestSent: new Channel(),
  createBooking: new Channel(),
  guestListToCreate: new Channel(),
};

export const monthsOptions = [
  "Январь",
  "Февраль",
  "Март",
  "Апрель",
  "Май",
  "Июнь",
  "Июль",
  "Август",
  "Сентябрь",
  "Октябрь",
  "Ноябрь",
  "Декабрь",
].map((month, idx) => ({ value: idx, label: month }));

export const yearsOptions = (start: number) =>
  _.range(start, new Date().getFullYear() + 6, 1).map((year, idx) => ({
    value: idx,
    label: year.toString(),
  }));

export const predata = new PreData();

export function makeid(l: number) {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (let i = 0; i < l; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}

export const FORMATS = {
  ddmmyyyy: "DD.MM.YYYY",
  yyyymmdd: "YYYY-MM-DD",
  timestamp: "YYYY-MM-DD[T]HH:mm:ss",
};

type StatusToIcons = Record<
  Status["system_name"] | "undefined",
  { text: string; icon: string }
>;

export const matchingIconsAndTranslateToStatus: StatusToIcons = {
  CLOSED: {
    text: ETranslations.BOOKING_STATUS_CLOSED,
    icon: ICONS.alarmEnd,
  },
  CANCELED: {
    text: ETranslations.BOOKING_STATUS_CANCELED,
    icon: ICONS.alarmCancel,
  },
  NOT_COME: {
    text: ETranslations.BOOKING_STATUS_CANCELED,
    icon: ICONS.alarmCancel,
  },
  IN_HALL: {
    text: ETranslations.BOOKING_STATUS_IN_HALL,
    icon: ICONS.alarmHall,
  },
  NEW: { text: ETranslations.BOOKING_STATUS_NEW, icon: ICONS.alarmNew },
  CONFIRMED: {
    text: ETranslations.BOOKING_STATUS_CONFIRMED,
    icon: ICONS.alarmNew,
  },
  ARRIVED: {
    text: ETranslations.BOOKING_STATUS_ARRIVED,
    icon: ICONS.alarmNew,
  },
  WAIT_LIST: {
    text: ETranslations.BOOKING_STATUS_WAIT_LIST,
    icon: ICONS.alarm,
  },
  EXTERNAL: {
    text: ETranslations.BOOKING_STATUS_EXTERNAL,
    icon: ICONS.alarmNew,
  },
  undefined: {
    text: ETranslations.BOOKING_STATUS_UNDEFINED,
    icon: ICONS.clocks,
  },
};

export function areArrsEqual(
  arr1?: (number | string | boolean | null | undefined)[],
  arr2?: (number | string | boolean | null | undefined)[],
) {
  if (_.isNil(arr1) && !_.isNil(arr2)) {
    return false;
  }
  if (!_.isNil(arr1) && _.isNil(arr2)) {
    return false;
  }
  if (_.isNil(arr1) || _.isNil(arr2)) {
    return true;
  }
  return _.isEqual([...arr1].sort(), [...arr2].sort());
}

export function areBookingInfoFiltersEq(
  bif1: BookingInfoFilter,
  bif2: BookingInfoFilter,
): boolean {
  return [
    areArrsEqual(bif1.place_id, bif2.place_id),
    bif1.date === bif2.date,
    bif1.time === bif2.time,
    bif1.guests === bif2.guests,
    bif1.visit_time === bif2.visit_time,
    bif1.table_type === bif2.table_type,
  ].every((a) => a);
}

export function areSchemePropsEq(
  prev?: SchemeDrillDown,
  cur?: SchemeDrillDown,
): boolean {
  if (cur === undefined && prev === undefined) {
    return true;
  }
  return (
    !!cur &&
    !!prev &&
    areArrsEqual(cur.tableNumber, prev.tableNumber) &&
    cur.type === prev.type &&
    cur.time === prev.time
  );
}

export function singleOptionTableType(arr: number[]): SingleOption[] {
  const newArr = arr.map((value) => new SingleOption(value, String(value)));
  newArr.push(new SingleOption(-1, "Любой"));
  return newArr;
}

export function singleOptionTable(tables: Table[]): SingleOption[] {
  return tables.map(
    (table) => new SingleOption(table.table_id, String(table.number)),
  );
}

export function singleOptionTime(arr: string[]): SingleOption[] {
  return arr.map((value, i) => ({ value: i, label: value.slice(0, 5) }));
}

export function visitTimeToHours(values: number[]): SingleOption[] {
  const hours = _.orderBy(values).map((h) => Math.floor(h / 60));
  const set = new Set(hours);
  return Array.from(set).map(
    (item, i) => new SingleOption(i, _.get(HOURS[item], "label")),
  );
}
// todo: переделать метод, не соответствует действительности
export const getBookPictureStatus = (all: BookingResponse[]) =>
  all.map((card) => {
    let bookStatus:
      | "not-booked"
      | "booked"
      | "booked-paid"
      | "booked-not-paid"
      | "not-booked-not-paid"
      | "not-booked-paid";
    bookStatus =
      card.status.system_name === "CONFIRMED" ? "booked" : "not-booked";
    if (card.deposit_status && card.deposit_status_reserv) {
      bookStatus += "-paid";
    } else if (card.deposit_status) {
      bookStatus += "-not-paid";
    }
    return { ...card, time: M(card.date).format("HH:mm"), bookStatus };
  });

export const isPageHall = () => window.location.href.includes("hall-scheme");
export const isPageCreateBook = () =>
  window.location.href.includes("create-booking");
export const isPageDashboard = () => window.location.href.includes("dashboard");
export const isPageRequests = () => window.location.href.includes("requests");
export const isPageGuests = () => window.location.href.includes("guests");
export const isPageSettings = () => window.location.href.includes("settings");
export const isPageSchedule = () =>
  window.location.href.includes("schedule-landing");

export function getScheduleIconColor(load: number, limitLoad: number) {
  if (load >= limitLoad) {
    return "red";
  }
  if (load === 0) {
    return "green";
  }
  return "orange";
}

// TODO refactoring: unification //////
export function emptyStringIfNull(
  val: string | string[] | null | undefined,
): string {
  return Array.isArray(val)
    ? val
        .map((item) => (_.isNil(item) || item === "null" ? "" : item))
        .join(" ")
    : _.isNil(val) || val === "null"
      ? ""
      : val;
}

export const getFullName = (
  name: string | null | undefined,
  middleName: string | null | undefined,
  surname: string | null | undefined,
) =>
  (
    (name || "") +
    (middleName ? ` ${middleName} ` : " ") +
    (surname || "")
  ).trim();

export const getShortName = (
  name: string | null | undefined,
  middleName: string | null | undefined,
  surname: string | null | undefined,
) => {
  const nameInitial = name?.replace(/.*?(\S).*/, "$1. ") || "";
  const middleNameInitial = middleName?.replace(/.*?(\S).*/, "$1. ") || "";

  return `${nameInitial}${middleNameInitial}${surname || ""}`;
};

export const clientShortName = (
  client: Partial<Client | Contact | ManagerialClient> | undefined,
): string => (client ? `${client.name} ${client.surname}` : "");
// ////////////////////////////////////

// booking
// TODO check similar funcs => unification
export function transformTimeKey(str: string) {
  let transformedObject = str;
  if (transformedObject) {
    const arrMatch = transformedObject.match(/[0-9]+/g);
    // @ts-ignore
    transformedObject = `${arrMatch[2]}.${arrMatch[1]}.${arrMatch[0]} ${arrMatch[3]}:${arrMatch[4]}`;
  }
  return transformedObject;
}
// modals
export const translateParamsMapper: { [name: string]: keyof typeof ICONS } = {
  Дата: "calLight",
  "Номера столов": "seat",
  "Время визита": "alarm",
  "Количество персон": "people",
  "Депозит внесён": "greyRuble",
  "Сумма депозита": "greyRuble",
  Комментарий: "comment",
  Источник: "source",
  Телефон: "call",
  Email: "email",
};

// booking -changes
export const humanizeParams = (params: ChangesParams[]) => {
  // eslint-disable-next-line
  let newParams = [
    ...params.filter(
      (item) =>
        !["Клиент_id", "Ресторан", "Статус", "Пользователь"].includes(
          item.field,
        ),
    ),
  ];
  newParams = [
    ...newParams.map((item) => {
      if (item.field === "Дата" && typeof item.new === "string") {
        item.new = transformTimeKey(item.new);
        item.old =
          typeof item.old === "string" && item.old !== undefinedString
            ? transformTimeKey(item.old)
            : undefinedString;
      }
      if (item.field === "Депозит внесён") {
        if (typeof item.new === "boolean") {
          item.new = item.new ? "Да" : "Нет";
        } else if (!item.new) {
          item.new = undefinedString;
        }
        if (typeof item.old === "boolean") {
          item.old = item.old ? "Да" : "Нет";
        } else if (!item.old) {
          item.old = undefinedString;
        }
      }
      if (item.field === "Телефон") {
        if (!item.new) {
          item.new = undefinedString;
        }
        if (!item.old) {
          item.old = undefinedString;
        }
      }
      if (item.field === "Время визита") {
        if (item.new && typeof item.new === "number") {
          item.new = SnapTime.constructorM(item.new).humanize;
        } else if (!item.new) {
          item.new = undefinedString;
        }

        if (item.old && typeof item.old === "number") {
          item.old = SnapTime.constructorM(item.old).humanize;
        } else if (!item.old) {
          item.old = undefinedString;
        }
      }
      if (item.field === "Сумма депозита" && typeof item.new === "string") {
        item.new = transformTimeKey(item.new);
        item.old =
          typeof item.old === "string" && item.old !== undefinedString
            ? transformTimeKey(item.old)
            : undefinedString;
      }
      if (
        item.field === "Источник" &&
        typeof item.new === "object" &&
        !Array.isArray(item.new)
      ) {
        item.new = item.new.source_name;
        item.old =
          item.old && !Array.isArray(item.old) && typeof item.old === "object"
            ? item.old.source_name
            : undefinedString;
      }
      if (item.field === "Номера столов" && Array.isArray(item.new)) {
        // @ts-ignore
        item.new = item.new
          .map((t) => +t)
          .sort((a, b) => a - b)
          .join(", ");
        // @ts-ignore
        item.old = Array.isArray(item.old)
          ? item.old
              .map((t) => +t)
              .sort((a, b) => a - b)
              .join(", ")
          : undefinedString;
      }
      return item;
    }),
  ];

  return newParams;
};

/* eslint-disable */
export const animatePhone = (
  <svg
    className="animate-phone"
    width="16"
    height="16"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="phone-svg"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.71 16.67C20.66 13.78 16.54 12 12 12C7.46 12 3.34 13.78 0.29 16.67C0.11 16.85 0
    17.1 0 17.38C0 17.66 0.11 17.91 0.29 18.09L2.77 20.57C2.95 20.75 3.2 20.86 3.48 20.86C3.75 20.86 4 20.75 4.18 20.58C4.97 19.84 5.87
    19.22 6.84 18.73C7.17 18.57 7.4 18.23 7.4 17.83V14.73C8.85 14.25 10.4 14 12 14C13.6 14 15.15 14.25 16.6 14.72V17.82C16.6 18.21 16.83
    18.56 17.16 18.72C18.14 19.21 19.03 19.84 19.82 20.57C20 20.75 20.25 20.85 20.52 20.85C20.8 20.85 21.05 20.74 21.23 20.56L23.71
    18.08C23.89 17.9 24 17.65 24 17.37C24 17.1 23.89 16.85 23.71 16.67Z"
      fill="white"
    />
    <path
      className="dashes"
      d="M12.9999 2H10.9999V7H12.9999V2Z
    M6.39984 9.81003L7.80984 8.40003L4.25984 4.84003L2.83984 6.26003C2.94984 6.29003 6.39984 9.81003 6.39984 9.81003Z
    M17.4185 10.0001L16 8.58155L19.5714 5.00006L21 6.42863C20.8893 6.45881 17.4185 10.0001 17.4185 10.0001Z"
      fill="white"
    />
  </svg>
);
/* eslint-enable */

// booking
export const findAndScrollToActiveCard = () => {
  const activeCard = document.querySelector(".card.active"),
    cardList = document.querySelector("section.active .cards.opened");
  if (!cardList || !activeCard) {
    return;
  }

  const scrollValue = document.body.clientWidth <= 1366 ? 745 : 800,
    topPosActiveCard = activeCard.getBoundingClientRect().bottom;

  if (topPosActiveCard > scrollValue) {
    cardList.scrollBy({
      top: topPosActiveCard - scrollValue,
      left: 0,
      behavior: "smooth",
    });
  }
};

// modals
/* eslint-disable */
export const translit = (str: string) => {
  const ru: any = {
    а: "a",
    б: "b",
    в: "v",
    г: "g",
    д: "d",
    е: "e",
    ё: "e",
    ж: "j",
    з: "z",
    и: "i",
    к: "k",
    л: "l",
    м: "m",
    н: "n",
    о: "o",
    п: "p",
    р: "r",
    с: "s",
    т: "t",
    у: "u",
    ф: "f",
    х: "h",
    ц: "c",
    ч: "ch",
    ш: "sh",
    щ: "shch",
    ы: "y",
    э: "e",
    ю: "u",
    я: "ya",
  };
  let newStr = "";

  str = str.replace(/[ъь]+/g, "").replace(/й/g, "i");

  for (let i = 0; i < str.length; i++) {
    if (ru[str[i]]) {
      newStr = newStr.concat(ru[str[i]]);
    } else if (!ru[str[i].toLowerCase()]) {
      newStr = newStr.concat(str[i]);
    } else {
      const symbol = ru[str[i].toLowerCase()].replace(/^(.)/, (match: string) =>
        match.toUpperCase(),
      );
      newStr = newStr.concat(symbol);
    }
  }

  return newStr;
};
/* eslint-enable */

export const validatorPhone = (
  prevPhone: string,
  newPhone: string,
): string | undefined => {
  if (newPhone.length > 18) {
    return undefined;
  }
  // ADD
  if (prevPhone.length < newPhone.length && /[^0-9]/.test(newPhone.slice(-1))) {
    return undefined;
  }
  // REMOVE
  if (prevPhone.length > newPhone.length && /[^0-9]/.test(newPhone.slice(-1))) {
    let newValue = newPhone.slice(0, newPhone.length - 1);

    if (/[^0-9]/.test(prevPhone.slice(-1))) {
      // delete 5 in "+7 (925) "
      if (/[0-9]/.test(newValue.slice(-1))) {
        newValue = newValue.slice(0, newValue.length - 1);
      }
    }
    if (/[^0-9]/.test(newValue.slice(-1))) {
      newValue = newValue.slice(0, newValue.length - 1);
    }

    // eslint-disable-next-line no-param-reassign
    newPhone = newValue || "";
  }
  return getMaskPhone(newPhone.replace(/[^0-9]/g, ""));
};

// export const optionsStatusBooking = [
//   {value: 1, label: ETranslations.BOOKING_STATUS_CLOSED, statuses: [BookingStatus.CLOSED]},
//   {value: 2, label: ETranslations.BOOKING_STATUS_CANCELED, statuses: [BookingStatus.CANCELED]},
//   {value: 3, label: ETranslations.BOOKING_STATUS_DID_NOT_COME, statuses: [BookingStatus.NOT_COME]},
//   {value: 4, label: ETranslations.BOOKING_STATUS_IN_HALL, statuses: [BookingStatus.IN_HALL]},
//   {value: 5, label: ETranslations.BOOKING_STATUS_NEW, statuses: getBookingStatus(["CONFIRMED", "NEW", "EXTERNAL"])},
//   {value: 6, label: ETranslations.BOOKING_STATUS_WAIT_LIST, statuses: [BookingStatus.WAIT_LIST]},
// ];

export const getCrossIcon = (onClickCallback: () => void) => (
  <svg
    aria-hidden="true"
    className="cross"
    focusable="false"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    onClick={onClickCallback}
  >
    <path
      d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697
      0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652
      3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229
      0 1.698z"
    />
  </svg>
);

export function generateLogin(user: AppointUser, numberAttempt: number) {
  const [surname = "", name = "", middleName = ""] = user.name.split(" ");
  const translitedName = translit(name.slice(0, numberAttempt));
  const translitedMiddleName =
    name.length <= numberAttempt
      ? translit(middleName.slice(0, numberAttempt - name.length))
      : "";
  const translitedSurname = translit(surname);
  const translitedRest = translit(
    predata.restsMapper[user.restaurant_id]?.label?.slice(0, 3) || "",
  );

  const login = `${translitedName}${translitedSurname}${translitedMiddleName}${
    numberAttempt > 1 ? `_${translitedRest}` : ""
  }`;
  return login.toLocaleLowerCase();
}

export function hasMiddleName(user: AppointUser, numberAttempt: number) {
  const [, name = "", middleName = ""] = user.name.split(" ");
  const translitedMiddleName =
    name.length <= numberAttempt
      ? translit(middleName.slice(0, numberAttempt - name.length))
      : "";

  if (name.length <= numberAttempt && translitedMiddleName.length === 0) {
    return false;
  }
  return true;
}

export const stopPropagation = (e: SyntheticEvent) => e.stopPropagation();

export const hourTrunc = (value: number) => Math.trunc(value / 60);
export const minuteTrunc = (value: number) => value % 60;
export const getTimeHoursAndMinutes = (value: number) => {
  return {
    hours: hourTrunc(value),
    minutes: minuteTrunc(value),
  };
};

export const instanceOfHistory = (
  object: BookingHistoryDTO["booking"] | BookingOrder,
): object is BookingHistoryDTO["booking"] =>
  "seat_type" in object &&
  (object.seat_type === "BOOK" ||
    object.seat_type === "MANAGER_BOOK" ||
    object.seat_type === "IN_WALK");

export const transformCategoryName = (str: Status["category"]) =>
  str.replace(
    /(\p{L})([\p{L}]+)([^\p{L}])?([\p{L}]+)/gu,
    (_i, p1, p2, p3, p4) => p1 + (p3 ? `${p2}-${p4}` : p2 + p4).toLowerCase(),
  ) as `${Capitalize<string>}-${string}` | Capitalize<string>;
