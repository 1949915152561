import { useFetchAllRestaurantsQuery } from "features/api/restaurants";
import {
  useGetAllGroupsQuery,
  useGetAllUsersQuery,
} from "features/api/user-api";
import { useIntl } from "react-intl";
import { useLoaderData } from "react-router-dom";
import { invariant } from "utils";

import type { loader } from "./api/loader";
import { getRoleListOptions } from "./utils";

export const userRoleList = () => {
  const filter = useLoaderData<typeof loader>();

  const { formatMessage } = useIntl();

  const restaurants = useFetchAllRestaurantsQuery();
  const groups = useGetAllGroupsQuery();
  const users = useGetAllUsersQuery();

  const error = restaurants.error || groups.error || users.error;

  // Выбрасываем исключение, если не удалось загрузить хоть что-то
  invariant(!error, JSON.stringify(error));

  const isLoading =
    restaurants.isLoading || groups.isLoading || users.isLoading;

  return {
    isLoading,
    options: isLoading
      ? undefined
      : getRoleListOptions({
          restaurants: restaurants.data!,
          groups: groups.data!,
          users: users.data!,
          filter,
          t: formatMessage,
        }),
    filter,
  };
};
