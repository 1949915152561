import cn from "classnames";
import { PlaceSelect } from "components/PlaceSelect";
import React from "react";
import { Field } from "react-final-form";
import { useCheckPermission } from "services/permissionChecker";
import { BottomBar, Labeled } from "ui-kit";

import { BookingOrder } from "../../../types/booking";
import { ETranslations } from "../../../types/translates";
import { capitalizeString } from "../../../utils";
import { ChoiceClientInfoRedux } from "../sub-renders/client";
import { DataChips } from "../sub-renders/data-chips";
import { ChoiceDateInput, ChoiceTimeInput } from "../sub-renders/date-time";
import { ChoiceTimeInputNew } from "../sub-renders/date-time-new";
import { ChoiceDepositInput } from "../sub-renders/deposit";
import { ChoiceHostessInput } from "../sub-renders/hostess";
import {
  ChoiceAutoTableInputRedux,
  ChoicePersonsInput,
  ChoiceTablesInputRedux,
} from "../sub-renders/location";
import { ChoiceNotesInput } from "../sub-renders/note";
import { ChoiceClientPhoneInputRedux } from "../sub-renders/phone";
import { ChoiceSourceInput } from "../sub-renders/source";
import { ChoiceTagsInput } from "../sub-renders/tags";
import {
  ChoiceVisitTimeButtonsInput,
  ChoiceVisitTimeControls,
} from "../sub-renders/visit-time";
import { requiredField } from "../validators/validators";
import styles from "./Form.module.scss";
import { Edit } from "./FormControls/Edit";
import { OrderControls } from "./FormControls/OrderControls";
import useCreateFromOrder from "./hooks/useCreateFromOrder";

export const CreateFromOrder: React.FC<any> = ({
  values,
  handleSubmit,
  valid,
  rest,
}) => {
  const {
    isNewDesign,
    getIntlJoinedParts,
    disable,
    intl,
    getIntlNumberOf,
    isRussianLocale,
    intlTitles,
    getIntlBaseErrorNotSelected,
    userList,
    order,
  } = useCreateFromOrder();
  const { hasPermissionFor } = useCheckPermission();

  return (
    <>
      <form className={cn(styles.form, "custom-scroll")}>
        <div className={styles.formElement}>
          <div className={styles.eachElementGridEdit}>
            <div
              className={cn(
                isNewDesign ? styles.dateTimeNewEdit : styles.dateTimeEdit,
              )}
            >
              <div className={styles.datePicker}>
                <Labeled
                  as="div"
                  className=""
                  label={getIntlJoinedParts([
                    ETranslations.BASE_DATE,
                    ETranslations.AND,
                    ETranslations.BASE_TIME,
                  ])}
                  required
                >
                  <Field
                    component={ChoiceDateInput}
                    disabled={disable}
                    name="date"
                    validate={(value) =>
                      requiredField(value, "Дата не указана")
                    }
                  />
                </Labeled>
              </div>
              <Field
                component={DataChips}
                disabled={disable}
                isCreateBooking={false}
                name="date"
                validate={(value) =>
                  requiredField(
                    value,
                    intl.formatMessage({
                      id: ETranslations.ERROR_DATE_NOT_SPECIFIED,
                    }),
                  )
                }
              />
              {!isNewDesign && (
                <ChoiceTimeInput
                  disabled={disable}
                  allowValueFromOutsideRange
                />
              )}
            </div>

            {isNewDesign && (
              <div
                className={cn(styles.fullRow, styles.twoCol, styles.gap12, {})}
              >
                <ChoiceTimeInputNew
                  disabled={disable}
                  isEditMode={!disable}
                  allowValueFromOutsideRange
                />
              </div>
            )}
          </div>
        </div>

        <div className={styles.formElement}>
          <div className={styles.eachElementGridEdit}>
            <Field
              className={cn("phone book add required", { hasValue: true })}
              component={ChoiceClientPhoneInputRedux}
              disabled={disable}
              label={intl.formatMessage({ id: ETranslations.PHONE })}
              name="phone"
              validate={(value) => !value}
              autoFocus
              isNewDesign
              required
            />
            <div className={styles.twoElems}>
              <Field
                component={ChoiceClientInfoRedux}
                name="client"
                showMenu={false}
                disable
                isCreateBooking
                isNewDesign
              />
            </div>
            <div className={cn(styles.twoElems, styles.gap16)}>
              <Field
                className={cn("persons", {
                  hasValue: !requiredField(values.persons),
                })}
                component={ChoicePersonsInput}
                disabled={disable}
                label={capitalizeString(
                  intl.formatMessage({ id: ETranslations.PLURAL_GUESTS_NOM }),
                )}
                name="persons"
                validate={(value) =>
                  requiredField(
                    value,
                    intl.formatMessage({
                      id: ETranslations.ERROR_REQUIRED_FIELD,
                    }),
                  )
                }
                required
              />

              <div>
                <Labeled
                  as="div"
                  className={cn(styles.visit, styles.bookingLabel)}
                  label={intl.formatMessage({ id: ETranslations.DURATION })}
                >
                  <div className={styles.visitTimeNewDesign}>
                    <Field
                      component={ChoiceVisitTimeButtonsInput}
                      disabled={disable}
                      name="visit_time"
                      newDesign
                    />
                    <Field
                      component={ChoiceVisitTimeControls}
                      disabled={disable}
                      name="visit_time"
                      validate={(value) => !value}
                      newDesign
                    />
                  </div>
                </Labeled>
              </div>
            </div>
            <div className={styles.twoElems}>
              <Labeled
                label={getIntlNumberOf(
                  isRussianLocale
                    ? ETranslations.PLURAL_HALLS_ALT
                    : ETranslations.PLURAL_HALL,
                )}
                required
              >
                <PlaceSelect disabled={disable} styles={styles} isNewDesign />
              </Labeled>
              <div className={styles.twoElemsTabelsEdit}>
                <Labeled label={intlTitles.tableNumber} required>
                  <div className="openSchemeContainer">
                    <Field
                      component={ChoiceTablesInputRedux}
                      disabled={disable}
                      name="tables"
                      validate={(value) =>
                        requiredField(
                          value,
                          getIntlBaseErrorNotSelected(intlTitles.tableNumber),
                        )
                      }
                      isShowTableScheme
                    />
                  </div>
                </Labeled>
                <ChoiceAutoTableInputRedux disabled={disable} />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.formElement}>
          <div className={styles.eachElementGridEdit}>
            <Labeled
              label={intl.formatMessage({ id: ETranslations.RESERVATION_TAGS })}
            >
              <Field
                component={ChoiceTagsInput}
                disabled={disable}
                name="tags"
              />
            </Labeled>
            <div className={styles.fullRowEdit}>
              <Labeled
                className={styles.bookingLabel}
                label={intl.formatMessage({ id: ETranslations.DEPOSIT })}
              >
                <Field
                  component={ChoiceDepositInput}
                  disabled={disable}
                  name="deposit"
                  isNewDesign
                />
              </Labeled>
            </div>
            <div className={cn(styles.twoElems, styles.soruceAndDepositEdit)}>
              <Labeled label={intl.formatMessage({ id: ETranslations.SOURCE })}>
                <Field
                  component={ChoiceSourceInput}
                  disabled={disable}
                  name="source"
                />
              </Labeled>

              <Labeled
                label={intl.formatMessage({ id: ETranslations.HOSTESS })}
                required
              >
                <Field
                  component={ChoiceHostessInput}
                  disabled={disable}
                  name="hostess"
                  options={userList}
                />
              </Labeled>
            </div>

            <div className={styles.fullRowEdit}>
              <Field
                component={ChoiceNotesInput}
                disabled={disable}
                label={getIntlJoinedParts([
                  ETranslations.COMMENT,
                  ETranslations.FOR_BOOKING,
                ])}
                name="comment"
              />
            </div>

            <div className={styles.fullRowEdit}>
              <Field
                className={cn(styles.bookingLabel, styles.notes)}
                component={ChoiceNotesInput}
                label={intl.formatMessage({ id: ETranslations.TABLE_PLACE })}
                name="notes"
                disabled
              />
            </div>
          </div>
        </div>
        <Field initialValue={rest} name="rest">
          {({ input }) => <input type="hidden" {...input} />}
        </Field>
      </form>
      {hasPermissionFor("ACCESS_ORDER_UPDATE") && (
        <BottomBar>
          <BottomBar.Part placement="left">
            <OrderControls
              order={order as BookingOrder}
              submit={handleSubmit}
              valid={valid}
            />
          </BottomBar.Part>
          <BottomBar.Part placement="right">
            <Edit showSave={false} />
          </BottomBar.Part>
        </BottomBar>
      )}
    </>
  );
};
