import { localResourcesApi } from "features/api/dictionaries-api";
import type { LoaderFunction } from "react-router-dom";
import { loadStore } from "storage";
import { transformLocale, tryCatch, validateLocale } from "utils";

export const loader = (async ({ request, context }, handle) => {
  const redirectTo = new URL(request.url).searchParams.get("redirectTo");
  const localeFromLocalStorage = tryCatch(() =>
    JSON.parse(localStorage.getItem("locale") || "null"),
  );
  const locale = transformLocale(validateLocale(localeFromLocalStorage));

  await loadStore().then((store) =>
    store.dispatch(
      localResourcesApi.endpoints.fetchTranslates.initiate(locale),
    ),
  );
  return redirectTo;
}) satisfies LoaderFunction;
