import { restaurantSelector } from "features/AppContext";
import _ from "lodash";
import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useSelector } from "react-redux";

import { type GrantedUser, User } from "../models/user.model";
import { AuthService } from "../services/auth.service";

//FIXME: убрать полностью контекст, когда будут доделаны ручки с ролями/пользователями

interface AppUsersContext {
  authUser: User | undefined;
  userList: GrantedUser[];
  currentUser: GrantedUser | undefined;
}

const AppUsers = createContext<AppUsersContext>({
  authUser: undefined,
  userList: [],
  currentUser: undefined,
});

export function AppUsersProvider(props: PropsWithChildren<{}>) {
  const restaurant = useSelector(restaurantSelector);
  const [authUser, setAuthUser] = useState<User | undefined>(undefined);
  const [userList, setUserList] = useState<GrantedUser[]>([]);
  const [currentUser, setCurrentUser] = useState<GrantedUser | undefined>(
    undefined,
  );

  return (
    <AppUsers.Provider {...props} value={{ authUser, userList, currentUser }} />
  );
}

export const useAppUsers = () => useContext(AppUsers);
