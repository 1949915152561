import { FetchBaseQueryArgs } from "@reduxjs/toolkit/dist/query/fetchBaseQuery";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { AuthService } from "services/auth.service";

import { apiAuth, apiFO, apiINT, apiINT2 } from "./constants";

// TODO это надо переписать и отказаться от глобальных каналов
export const fetchWithAuth: FetchBaseQueryArgs["fetchFn"] = async (
  reqInfo,
  init,
) => {
  const req = new Request(reqInfo, init);
  req.headers.set("Content-Type", "application/json");
  return AuthService.fetchWithAuthentication(req);
};

export const api = createApi({
  reducerPath: "coreApi",
  baseQuery: fetchBaseQuery({
    baseUrl: apiINT,
    fetchFn: fetchWithAuth,
  }),
  endpoints: () => ({}),
  tagTypes: [
    "Bookings",
    "Booking",
    "Statuses",
    "BookingStatus",
    "Timeline",
    "BookingExtraStatus",
    "PlacesTables",
    "Shifts",
  ],
});

export const api2 = createApi({
  reducerPath: "coreApi2",
  baseQuery: fetchBaseQuery({
    baseUrl: apiINT2,
    fetchFn: fetchWithAuth,
  }),
  endpoints: () => ({}),
  tagTypes: ["Bookings", "Booking", "BookingStatus", "Timeline"],
});

export const apiFrontOffice = createApi({
  reducerPath: "apiFrontOffice",
  baseQuery: fetchBaseQuery({
    baseUrl: apiFO,
    fetchFn: fetchWithAuth,
  }),
  endpoints: () => ({}),
  tagTypes: ["Feeds"],
});

export const relativePathApi = createApi({
  reducerPath: "relativePathApi",
  tagTypes: ["translates"],
  baseQuery: fetchBaseQuery({ baseUrl: "" }),
  endpoints: () => ({}),
});

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: apiAuth,
  }),
  endpoints: () => ({}),
  tagTypes: ["Auth"],
});
