import dayjs from "dayjs";
import { restaurantSelector } from "features/AppContext";
import type { RestaurantId } from "models/booking.model";
import type { ClientId } from "models/client.model";
import { type ReactNode, createContext, useContext, useState } from "react";
import { useSelector } from "react-redux";
import { invariant } from "utils";

export type CreateBookingFormClient = {
  clientId?: ClientId;
  phone?: string;
  surname?: string;
  name?: string;
  middleName?: string;
};

type FormValues = {
  restaurantId: RestaurantId;
  bookingDate: "YYYY-MM-DD" | (string & {});
  bookingTime?: string;
  visitors?: number;
  client?: CreateBookingFormClient;
  shift?: {
    id: number;
    startHour: number;
    endHour: number;
    places: number[];
  };
};

type ValidationState = {
  step1?: boolean;
  step2?: boolean;
  step3?: boolean;
  step4?: boolean;
  step5?: boolean;
};
type CreateBookingFormContext = {
  formValues: FormValues;
  validationState: ValidationState;
  updateValues: (newValues: Partial<FormValues>) => void;
  updateValidationState: (newValidationState: ValidationState) => void;
  createBooking: () => void;
};

const createBookingFormContext = createContext<CreateBookingFormContext | null>(
  null,
);

export const useCreateBookingFormContext = () => {
  const value = useContext(createBookingFormContext);
  return (value ||
    invariant(
      value,
      "CreateBookingForm context not passed, please wrap your components with <CreateBookingFormProvider />",
    )) as CreateBookingFormContext;
};

const CreateBookingFormProvider = ({ children }: { children: ReactNode }) => {
  const appRestaurant = useSelector(restaurantSelector);
  const [formValues, setFormValues] = useState<FormValues>({
    restaurantId: appRestaurant.restaurant_id,
    bookingDate: dayjs.tz().format("YYYY-MM-DD"),
  });
  const [validationState, setValidationState] = useState<ValidationState>({
    step1: undefined,
    step2: undefined,
    step3: undefined,
    step4: undefined,
    step5: undefined,
  });

  const updateValues = (newValues: Partial<FormValues>) =>
    setFormValues((prev) => ({ ...prev, ...newValues }));

  const updateValidationState = (newValidationState: ValidationState) =>
    setValidationState((prev) => ({ ...prev, ...newValidationState }));
  return (
    <createBookingFormContext.Provider
      value={{
        formValues,
        validationState,
        updateValues,
        updateValidationState,
        createBooking: () => console.log("create"),
      }}
    >
      {children}
    </createBookingFormContext.Provider>
  );
};

CreateBookingFormProvider.displayName = "CreateBookingFormProvider";

export { CreateBookingFormProvider };
