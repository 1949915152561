import { OrganizationId, SourceType } from "models/source.model";
import type { IntlShape } from "react-intl";
import { ETranslations } from "types/translates";
import { z } from "zod";


export const getSourceFormSchema = (t: IntlShape["formatMessage"]) =>
  z.object({
    surname: z.union([
      z.string().trim().pipe(z.literal("")),
      z
        .string()
        .trim()
        .regex(
          /^[\p{sc=Cyrillic}|\p{sc=Latin}]+$/u,
          ETranslations.ONLY_LATIN_CYRILLIC,
        )
        .max(30, t({ id: ETranslations.FIELD_TOO_LONG }, { value: 30 }))
        .optional(),
    ]),
    name: z
      .string()
      .trim()
      .regex(
        /^[\p{sc=Cyrillic}|\p{sc=Latin}]+$/u,
        ETranslations.ONLY_LATIN_CYRILLIC,
      )
      .max(30, t({ id: ETranslations.FIELD_TOO_LONG }, { value: 30 }))
      .min(1, ETranslations.FIELD_CANT_BE_EMPTY),
    middleName: z.union([
      z.string().trim().pipe(z.literal("")),
      z
        .string()
        .trim()
        .regex(
          /^[\p{sc=Cyrillic}|\p{sc=Latin}]+$/u,
          ETranslations.ONLY_LATIN_CYRILLIC,
        )
        .max(30, t({ id: ETranslations.FIELD_TOO_LONG }, { value: 30 }))
        .optional(),
    ]),
    phone: z
      .string()
      .trim()
      .regex(/^\+?[1-9]\d{1,14}$/, ETranslations.FIELD_INVALID)
      .min(1, ETranslations.FIELD_CANT_BE_EMPTY),
    additionalPhone: z.union([
      z.string().trim().pipe(z.literal("")),
      z
        .string()
        .trim()
        .regex(/^\+?[1-9]\d{1,14}$/, ETranslations.FIELD_INVALID)
        .optional(),
    ]),
    email: z
      .union([
        z.string().trim().pipe(z.literal("")),
        z
          .string()
          .email({ message: ETranslations.FIELD_INVALID })
          .max(50, t({ id: ETranslations.FIELD_TOO_LONG }, { value: 50 })),
      ])
      .optional(),
    type: SourceType,
    organizationId: OrganizationId.optional(),
    organizationName: z.string().optional(),
  });

export type SourceFormSchema = z.infer<ReturnType<typeof getSourceFormSchema>>;