import { type LoaderFunction, redirect } from "react-router-dom";
import { getPermissionChecker } from "services/permissionChecker";
import { loadStore } from "storage";

export const loader = (async () => {
  const store = await loadStore();
  const { hasPermissionFor } = await getPermissionChecker(store);

  if (!hasPermissionFor("SECTION_MANAGEMENT", true)) {
    return redirect("/hall-scheme");
  }
  return null;
}) satisfies LoaderFunction;
