import { debounce } from "lodash";
import { useLayoutEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

const assignAnchorIdsToButtons = (
  buttons: HTMLCollection,
  contentWidth: number,
): number => {
  let linkCount = 1;
  let accWidth = buttons[1].scrollWidth;
  buttons[1].id = `anchor-${linkCount}`;
  for (let i = 3; i < buttons.length; i += 2) {
    accWidth += buttons[i].scrollWidth;
    if (accWidth >= contentWidth) {
      accWidth = 0;
      linkCount++;
      buttons[i].id = `anchor-${linkCount}`;
    } else {
      buttons[i].removeAttribute("id");
    }
    accWidth++;
  }
  return linkCount;
};

export const useMultiShiftNavigation = () => {
  const { hash } = useLocation();
  const buttonsRef = useRef<HTMLDivElement>(null);
  const [links, setLinks] = useState<number>(0);

  const prev = hash
    ? hash.replace(
        /(?<=-)\d+/,
        (match) => `${Math.max(+match - 1, 0) || links}`,
      )
    : `#anchor-${links}`;
  const next = hash
    ? hash.replace(
        /(?<=-)\d+/,
        (match) => `${+match === links ? 1 : +match + 1}`,
      )
    : "#anchor-1";

  useLayoutEffect(() => {
    if (!buttonsRef.current) return;

    const observer = new ResizeObserver(
      debounce((entries) => {
        const contentWidth = entries[0].borderBoxSize[0].inlineSize + 32;
        const scrollWidth = entries[0].target.scrollWidth;

        if (scrollWidth <= contentWidth) {
          links && setLinks(0);
        } else {
          setLinks(
            assignAnchorIdsToButtons(entries[0].target.children, contentWidth),
          );
        }
      }, 100),
    );

    observer.observe(buttonsRef.current);
    return () => observer.disconnect();
  }, [links]);

  return { buttonsRef, links, prev, next };
};
